import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import { ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import {
  addAcadReq,
  apiError, batchReq, candReq, contactUsPostReq, delOrgProj,
  getAcadListReq, getAllJobs, getAllPJobs, getAPLPJobs, getBatchListReq,
  getCityListReq, getFileURLReq, getForgotPassword, getJobPositonById, getJobStatus,
  getListOrgProj, getLoginToken, getMentorAccess, getMentorStatusListReq, getObjective, getOrgListReq, getOrgProject,
  getPCandListReq, getPreviewResume, getProfile, getResumeDataObj,
  getResumeProfile, getSkillListReq, getStrmListReq, getSVDPJobs,
  getUnivListReq, getVideoURLReq, passKeyReq, registerCMPSReq, updateAcadListReq,
  updateAddAcadReq, updateAllJobs, updateAllPJobs, updateAPLPJobs, updateBatchListReq,
  updateBatchReq, updateCandReq, updateCityListReq, updateContactUsPostReq,
  updateDelOrgProjReq, updateFileURLReq, updateForgotPassword, updateJobPositonById,
  updateJobStatusReq, updateListOrgProj, updateMentorAccess, updateMentorStatusListReq, updateObjective, updateOrgListReq,
  updateOrgProject, updatePassKeyReq, updatePCandListReq, updatePreviewResume,
  updateProfile, updateRegisterCMPSPostReq, updateResumeDataObj, updateResumeProfile,
  updateSkillListReq, updateStrmListReq, updateSVDPJobs, updateToken, updateUnivListReq,
  updateUploadImageReq, updateUploadVideoReq, updateUserProfilePutReq, updateVideoURLReq,
  uploadImageReq, uploadVideoReq, userProfilePutReq,
} from './actions';
import { ListAcadResults } from '../../services/meta-data/list-acad/list-acad.service.types';
import { GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { FileURLResults } from '../../services/get-file-url/get-file-url.service.types';
import { ListCityResults } from '../../services/meta-data/list-city-loc/list-city.service.types';
import { ListOrgResults } from '../../services/meta-data/list-org/list-org.service.types';
import { ListSkillResults } from '../../services/meta-data/list-skills/list-skill.service.types';
import { UserProfilePutResults } from '../../services/update-profile/update-profile.service.types';
import { PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { UploadImageResults } from '../../services/upload-image/upload-image.service.types';
import { ResumeProfileResults } from '../../services/resume-data/get-cand-profile/get-profile.service.types';
import { ResumeObjResults } from '../../services/resume-data/objective/get-obj/get-obj.service.types';
import { ObjectiveResults } from '../../services/resume-data/objective/update-obj/objective.service.types';
import { JobPositionByIdResults } from '../../services/job-board/job-position-by-id/job-position-by-id.service.types';
import { ListJobsResults } from '../../services/job-board/list-jobs/list-jobs.service.types';
import { ListPJobsResults } from '../../services/job-board/list-personalized-jobs/list-p-jobs.service.types';
import { JobStatusResults } from '../../services/job-board/update-job-status/job-status.service.types';
import { OrgProjResults } from '../../services/resume-data/org-project/update-org-obj/org-proj.service.types';
import { ListOrgProjResults } from '../../services/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { DelOrgProjResults } from '../../services/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { ResumePreviewResults } from '../../services/resume-data/preview/get-preview.service.types';
import { UploadVideoResults } from '../../services/upload-video/upload-video.service.types';
import { VideoURLResults } from '../../services/get-video-url/get-video-url.service.types';
import { ListStrmResults } from '../../services/meta-data/list-stream/list-stream.service.types';
import { BatchPutResults } from '../../services/cmps-batches/add-batch/update-batch.service.types';
import { ListBatchResults } from '../../services/cmps-batches/list-batch/list-batch.service.types';
import { CandPutResults } from '../../services/candidates/update-candidate/update-cand.service.types';
import { ListPCandResults } from '../../services/candidates/pending-approval-list/list-pcand.service.types';
import { AcadAddResults } from '../../services/meta-data/add-academy/add-new-acad.service.types';
import { ListUnivResults } from '../../services/meta-data/list-university/list-univ.service.types';
import { RegisterCMPSResults } from '../../services/register-campus-user-by-cx/register.service.types';
import { ListMentorResults } from '../../services/mentors/mentor-list/list-mentor.service.types';
import { MentorAccessUpdateResults } from '../../services/mentors/mentor-access-update/mentor-update.service.types';

export const contactUsPOSTEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(campusXcelService.contactUsService(action.payload)).pipe(
    map((response : ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'CampusXcel', errorMessage: error.message })),
  )),
);
export const getAcadListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAcadListReq)),
  mergeMap((action) => from(campusXcelService.acadMetaListService(action.payload)).pipe(
    map((response : ListAcadResults) => updateAcadListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Acad Meta', errorMessage: error.message })),
  )),
);
export const registerCandidateEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerCMPSReq)),
  mergeMap((action) => from(campusXcelService.registerCMPSService(action.payload)).pipe(
    map((response : RegisterCMPSResults) => updateRegisterCMPSPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'CampusConnect', errorMessage: error.message })),
  )),
);
export const generateTokenEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getLoginToken)),
  mergeMap((action) => from(campusXcelService.genTokenService(action.payload)).pipe(
    map((response : GenTokenResults) => updateToken(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Login', errorMessage: error.message })),
  )),
);
export const getProfileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getProfile)),
  mergeMap((action) => from(campusXcelService.profileService(action.payload)).pipe(
    map((response : ProfileResults) => updateProfile(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Profile', errorMessage: error.message })),
  )),
);
export const getForgotPasswordEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getForgotPassword)),
  mergeMap((action) => from(campusXcelService.forgotPasswordService(action.payload)).pipe(
    map((response : ForgotPasswordResults) => updateForgotPassword(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'ForgotPassword', errorMessage: error.message })),
  )),
);
export const getFileURLEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getFileURLReq)),
  mergeMap((action) => from(campusXcelService.fileURLService(action.payload)).pipe(
    map((response : FileURLResults) => updateFileURLReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'File URL Update', errorMessage: error.message })),
  )),
);
export const getCityListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getCityListReq)),
  mergeMap((action) => from(campusXcelService.cityMetaListService(action.payload)).pipe(
    map((response : ListCityResults) => updateCityListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'City Meta', errorMessage: error.message })),
  )),
);
export const userProfilePutEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(userProfilePutReq)),
  mergeMap((action) => from(campusXcelService.userProfilePutService(action.payload)).pipe(
    map((response : UserProfilePutResults) => updateUserProfilePutReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile Update', errorMessage: error.message })),
  )),
);
export const userProfilePicEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(uploadImageReq)),
  mergeMap((action) => from(campusXcelService.userProfilePicService(action.payload)).pipe(
    map((response : UploadImageResults) => updateUploadImageReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile pic Update', errorMessage: error.message })),
  )),
);
export const passKeyEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(passKeyReq)),
  mergeMap((action) => from(campusXcelService.passKeyService(action.payload)).pipe(
    map((response : PassKeyPutResults) => updatePassKeyReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Pass Key Update', errorMessage: error.message })),
  )),
);
export const getOrgListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getOrgListReq)),
  mergeMap((action) => from(campusXcelService.orgMetaListService(action.payload)).pipe(
    map((response : ListOrgResults) => updateOrgListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Org Meta', errorMessage: error.message })),
  )),
);
export const getSkillListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getSkillListReq)),
  mergeMap((action) => from(campusXcelService.skillMetaListService(action.payload)).pipe(
    map((response : ListSkillResults) => updateSkillListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Skill Meta', errorMessage: error.message })),
  )),
);
export const getResumeProfileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResumeProfile)),
  mergeMap((action) => from(campusXcelService.resumeProfileService(action.payload)).pipe(
    map((response : ResumeProfileResults) => updateResumeProfile(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Resume Profile', errorMessage: error.message })),
  )),
);
export const updateObjectiveEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getObjective)),
  mergeMap((action) => from(campusXcelService.objectiveService(action.payload)).pipe(
    map((response : ObjectiveResults) => updateObjective(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Objective', errorMessage: error.message })),
  )),
);
export const getResumeObjEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResumeDataObj)),
  mergeMap((action) => from(campusXcelService.getResumeObjService(action.payload)).pipe(
    map((response : ResumeObjResults) => updateResumeDataObj(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Objective Data', errorMessage: error.message })),
  )),
);
export const updateJobStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getJobStatus)),
  mergeMap((action) => from(campusXcelService.jobStatusService(action.payload)).pipe(
    map((response : JobStatusResults) => updateJobStatusReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Job Status Update', errorMessage: error.message })),
  )),
);
export const listJobsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAllJobs)),
  mergeMap((action) => from(campusXcelService.listJobsService(action.payload)).pipe(
    map((response : ListJobsResults) => updateAllJobs(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'JobBoard', errorMessage: error.message })),
  )),
);
export const listPJobsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAllPJobs)),
  mergeMap((action) => from(campusXcelService.listPJobsService(action.payload)).pipe(
    map((response : ListPJobsResults) => updateAllPJobs(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Personal JobBoard', errorMessage: error.message })),
  )),
);
export const listAPLPJobsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAPLPJobs)),
  mergeMap((action) => from(campusXcelService.listAPLPJobsService(action.payload)).pipe(
    map((response : ListPJobsResults) => updateAPLPJobs(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Personal Applied JobBoard', errorMessage: error.message })),
  )),
);
export const listSVDPJobsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getSVDPJobs)),
  mergeMap((action) => from(campusXcelService.listSVDPJobsService(action.payload)).pipe(
    map((response : ListPJobsResults) => updateSVDPJobs(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Personal Saved JobBoard', errorMessage: error.message })),
  )),
);
export const jobsByIdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getJobPositonById)),
  mergeMap((action) => from(campusXcelService.jobPositionByIdsService(action.payload)).pipe(
    map((response : JobPositionByIdResults) => updateJobPositonById(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'JobBoard', errorMessage: error.message })),
  )),
);
export const updateOrgProjEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getOrgProject)),
  mergeMap((action) => from(campusXcelService.orgProjService(action.payload)).pipe(
    map((response : OrgProjResults) => updateOrgProject(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Org Project Update', errorMessage: error.message })),
  )),
);
export const listOrgProjEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getListOrgProj)),
  mergeMap((action) => from(campusXcelService.listOrgProjService(action.payload)).pipe(
    map((response : ListOrgProjResults) => updateListOrgProj(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'List Org Proj', errorMessage: error.message })),
  )),
);
export const deleteOrgProjEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(delOrgProj)),
  mergeMap((action) => from(campusXcelService.deleteOrgProjService(action.payload)).pipe(
    map((response : DelOrgProjResults) => updateDelOrgProjReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Org Proj', errorMessage: error.message })),
  )),
);
export const getResumePreviewEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getPreviewResume)),
  mergeMap((action) => from(campusXcelService.getResumePreviewService(action.payload)).pipe(
    map((response : ResumePreviewResults) => updatePreviewResume(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Resume Data', errorMessage: error.message })),
  )),
);
export const userProfileVideoEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(uploadVideoReq)),
  mergeMap((action) => from(campusXcelService.userProfileVideoService(action.payload)).pipe(
    map((response : UploadVideoResults) => updateUploadVideoReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile Video Update', errorMessage: error.message })),
  )),
);
export const getVideURLEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getVideoURLReq)),
  mergeMap((action) => from(campusXcelService.videoURLService(action.payload)).pipe(
    map((response : VideoURLResults) => updateVideoURLReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Video URL Update', errorMessage: error.message })),
  )),
);
export const getStrmListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getStrmListReq)),
  mergeMap((action) => from(campusXcelService.strmMetaListService(action.payload)).pipe(
    map((response : ListStrmResults) => updateStrmListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Strm Meta', errorMessage: error.message })),
  )),
);
export const batchEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(batchReq)),
  mergeMap((action) => from(campusXcelService.batchService(action.payload)).pipe(
    map((response : BatchPutResults) => updateBatchReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Batch Update', errorMessage: error.message })),
  )),
);
export const getBatchListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getBatchListReq)),
  mergeMap((action) => from(campusXcelService.batchListService(action.payload)).pipe(
    map((response : ListBatchResults) => updateBatchListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'List Batch Data', errorMessage: error.message })),
  )),
);
export const candUpdtEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(candReq)),
  mergeMap((action) => from(campusXcelService.candUpdtService(action.payload)).pipe(
    map((response : CandPutResults) => updateCandReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Candidate Update', errorMessage: error.message })),
  )),
);
export const getPCandListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getPCandListReq)),
  mergeMap((action) => from(campusXcelService.pCandListService(action.payload)).pipe(
    map((response : ListPCandResults) => updatePCandListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'List Pending Candidates Data', errorMessage: error.message })),
  )),
);
export const acadAddEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(addAcadReq)),
  mergeMap((action) => from(campusXcelService.addAcadService(action.payload)).pipe(
    map((response : AcadAddResults) => updateAddAcadReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Add Academy', errorMessage: error.message })),
  )),
);
export const getUnivEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getUnivListReq)),
  mergeMap((action) => from(campusXcelService.univListService(action.payload)).pipe(
    map((response : ListUnivResults) => updateUnivListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'List University Data', errorMessage: error.message })),
  )),
);

export const getMentorEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getMentorStatusListReq)),
  mergeMap((action) => from(campusXcelService.mentorListService(action.payload)).pipe(
    map((response : ListMentorResults) => updateMentorStatusListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'List Mentor Data', errorMessage: error.message })),
  )),
);

export const updateMentorAccessEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getMentorAccess)),
  mergeMap((action) => from(campusXcelService.mentorAccessService(action.payload)).pipe(
    map((response : MentorAccessUpdateResults) => updateMentorAccess(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Mentor Access Update', errorMessage: error.message })),
  )),
);

export const campusXcelEpics = combineEpics(
  contactUsPOSTEpic,
  getAcadListEpic,
  registerCandidateEpic,
  generateTokenEpic,
  getProfileEpic,
  getForgotPasswordEpic,
  getFileURLEpic,
  getCityListEpic,
  userProfilePutEpic,
  userProfilePicEpic,
  passKeyEpic,
  getOrgListEpic,
  getSkillListEpic,
  getResumeProfileEpic,
  getResumeObjEpic,
  updateObjectiveEpic,
  updateOrgProjEpic,
  jobsByIdEpic,
  listSVDPJobsEpic,
  listAPLPJobsEpic,
  listPJobsEpic,
  listJobsEpic,
  updateJobStatusEpic,
  listOrgProjEpic,
  deleteOrgProjEpic,
  getResumePreviewEpic,
  userProfileVideoEpic,
  getVideURLEpic,
  getStrmListEpic,
  batchEpic,
  getBatchListEpic,
  candUpdtEpic,
  getPCandListEpic,
  acadAddEpic,
  getUnivEpic,
  getMentorEpic,
  updateMentorAccessEpic,
);
