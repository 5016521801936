import {
  faLinkedinIn, faTwitter, faFacebookF, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router';
import { faCopyright, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import './home.scss';

export const WDSFooter: React.FC = () => {
  const history = useNavigate();
  return (
    <footer className="footer pt-3">
      <Row className="mx-2 pt-4">
        <Col lg="5">
          <div className="text-footer-know-campus">
            <span className="mb-2 ">
              Our mission is to help students reach their full potential
              and find their best match in the workforce.
            </span>
          </div>
          <div className="my-5">
            <Button onClick={() => { history('/register'); window.scrollTo(0, 0); }} className="intro-btn-filled">Register with us</Button>
          </div>
        </Col>
        <Col lg="1" className="my-2" />
        {/* <Col lg="3" className="my-2">
          <div className="text-footer-abou-count">
            <span>50,000 +</span>
          </div>
          <div className="text-footer-abou-title">
            <span>Members</span>
          </div>
        </Col>
        <Col lg="3" className="my-2">
          <div className="text-footer-abou-count">
            <span>100 +</span>
          </div>
          <div className="text-footer-abou-title">
            <span>Website Visit</span>
          </div>
        </Col>
        <Col lg="3" className="my-2">
          <div className="text-footer-abou-count">
            <span>100 +</span>
          </div>
          <div className="text-footer-abou-title">
            <span>Organizations</span>
          </div>
        </Col> */}
      </Row>
      <Row className="mx-2">
        <Col lg="3" className="my-2">
          <div className="align-start">
            <img width="80px" src="/images/home/footer-follow-us.png" alt=".." />
          </div>
          <div className="align-start pt-2">
            <div className="mb-2 mx-1">
              <Button
                className="btn-footer-icon align-center"
                color="default"
                href="https://www.linkedin.com/#"
                id="tooltiplinkedin"
                target="_blank"
              >
                <i className="fab"><FontAwesomeIcon icon={faLinkedinIn} className="footer-wds-icon" /></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltiplinkedin">
                Linkedin
              </UncontrolledTooltip>
            </div>
            <div className="mb-2 mx-1">
              <Button
                className="btn-footer-icon align-center"
                color="default"
                href="https://twitter.com/#"
                id="tooltiptweet"
                target="_blank"
              >
                <i className="fab"><FontAwesomeIcon icon={faTwitter} className="footer-wds-icon" /></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltiptweet">
                Twitter
              </UncontrolledTooltip>
            </div>
            <div className="mb-2 mx-1">
              <Button
                className="btn-footer-icon align-center"
                color="default"
                href="https://www.facebook.com/#"
                id="tooltipFb"
                target="_blank"
              >
                <i className="fab"><FontAwesomeIcon icon={faFacebookF} className="footer-wds-icon" /></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltipFb">
                Facebook
              </UncontrolledTooltip>
            </div>
            <div className="mb-2 mx-1">
              <Button
                className="btn-footer-icon align-center"
                color="default"
                href="https://www.instagram.com/#"
                id="tooltipInsta"
                target="_blank"
              >
                <i className="fab"><FontAwesomeIcon icon={faInstagram} className="footer-wds-icon" /></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltipInsta">
                Instagram
              </UncontrolledTooltip>
            </div>
          </div>
        </Col>
        <Col lg="1" className="my-2" />
        <Col lg="3" className="my-2">
          <div className="align-start">
            <span className="mb-2 text-footer-header">
              About
            </span>
          </div>
          <div className="pt-1">
            <span className="mb-2 text-footer-desc">
              As a team, we are committed to revolutionizing the education system
              and implementing innovative approaches that benefit both students and
              organizations.
            </span>
          </div>
        </Col>
        <Col lg="2" className="my-2" />
        <Col lg="3" className="my-2">
          <div className="align-start">
            <span className="mb-2 text-footer-header">
              Join us
            </span>
          </div>
          <div>
            <span className="mb-5">
              <p className="text-footer-desc"> <i className="fa fa-map-marker" /> {' '} WDSInfotech, Pune <br />
                <FontAwesomeIcon icon={faEnvelope} /> {' '} support@wdsinfotech.com
              </p>
            </span>
          </div>
        </Col>
        <Col xs="12">
          <div className="align-center pt-5 my-3">
            <span className="copyright">
              copyright {' '}<FontAwesomeIcon icon={faCopyright} />{' '}
              <a
                href="/"
                className="text-copyright-mail"
                rel="noreferrer"
                target="_blank"
                style={{
                  marginLeft: '2px', marginRight: '2px', fontSize: '15px', textDecoration: 'none',
                }}
              >
                wdsinfotech
              </a>
              {' '} 2020
            </span>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default WDSFooter;
