import { VideoURLResponse, VideoURLResults } from './get-video-url.service.types';

export const VideoURLTransform: (response: VideoURLResponse) => VideoURLResults = (videoURLResults) => {
  const { data, is_error, message } = videoURLResults;
  const result :VideoURLResults = {
    url: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.message = message;
    result.isError = is_error;
  } else {
    result.url = data ?? '';
    result.isError = is_error;
    result.message = '';
  }
  return result;
};
