import { deleteJSON } from '../../../../utils';
import { getConfig } from '../../../../utils/config/config';
import { DelOrgProjOptions, DelOrgProjResponse } from './del-org-proj.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();
export const DelOrgProjPutRequest = ({
  token, userType, requestType, orgId,
}: DelOrgProjOptions): Promise<DelOrgProjResponse> => {
  if (token) {
    return deleteJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}&orgId=${orgId}`, requestType, token);
  }
  return deleteJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, requestType, '');
};
