import {
  ListPCandService, ListPCandOptions, ListPCandResponse, ListPCandResults,
} from './candidates/pending-approval-list/list-pcand.service.types';
import { ListPCandRequest } from './candidates/pending-approval-list/list-pcand.service';
import { ListPCandTransform } from './candidates/pending-approval-list/list-pcand.transform';
import { CandPutRequest } from './candidates/update-candidate/update-cand.service';
import {
  CandPutService, CandPutOptions, CandPutResponse, CandPutResults,
} from './candidates/update-candidate/update-cand.service.types';
import { CandPutTransform } from './candidates/update-candidate/update-cand.transform';
import { BatchPutRequest } from './cmps-batches/add-batch/update-batch.service';
import {
  BatchPutService, BatchPutOptions, BatchPutResponse, BatchPutResults,
} from './cmps-batches/add-batch/update-batch.service.types';
import { BatchPutTransform } from './cmps-batches/add-batch/update-batch.transform';
import { ListBatchRequest } from './cmps-batches/list-batch/list-batch.service';
import {
  ListBatchService, ListBatchOptions, ListBatchResponse, ListBatchResults,
} from './cmps-batches/list-batch/list-batch.service.types';
import { ListBatchTransform } from './cmps-batches/list-batch/list-batch.transform';
import { ContactUsPostRequest } from './contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './contact-us/contact-us.service.types';
import { ContactUsTransform } from './contact-us/contact-us.transform';
import { ForgotPasswordRequest } from './forgot-password/forgot-password.service';
import {
  ForgotPasswordService, ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults,
} from './forgot-password/forgot-password.service.types';
import { ForgotPasswordTransform } from './forgot-password/forgot-password.transform';
import { GenTokenRequest } from './gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './gen-token/gen-token.service.types';
import { GenTokenTransform } from './gen-token/gen-token.transform';
import { FileURLRequest } from './get-file-url/get-file-url.service';
import {
  FileURLService, FileURLOptions, FileURLResponse, FileURLResults,
} from './get-file-url/get-file-url.service.types';
import { FileURLTransform } from './get-file-url/get-file-url.transform';
import { ProfileRequest } from './get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './get-profile/get-profile.service.types';
import { ProfileTransform } from './get-profile/get-profile.transform';
import { VideoURLRequest } from './get-video-url/get-video-url.service';
import {
  VideoURLService, VideoURLOptions, VideoURLResponse, VideoURLResults,
} from './get-video-url/get-video-url.service.types';
import { VideoURLTransform } from './get-video-url/get-video-url.transform';
import { JobPositionByIdRequest } from './job-board/job-position-by-id/job-position-by-id.service';
import {
  JobPositionByIdService, JobPositionByIdOptions, JobPositionByIdResponse, JobPositionByIdResults,
} from './job-board/job-position-by-id/job-position-by-id.service.types';
import { JobPositionByIdTransform } from './job-board/job-position-by-id/job-position-by-id.transform';
import { ListJobsRequest } from './job-board/list-jobs/list-jobs.service';
import {
  ListJobsService, ListJobsOptions, ListJobsResponse, ListJobsResults,
} from './job-board/list-jobs/list-jobs.service.types';
import { ListJobsTransform } from './job-board/list-jobs/list-jobs.transform';
import { ListPJobsRequest } from './job-board/list-personalized-jobs/list-p-jobs.service';
import {
  ListPJobsService, ListPJobsOptions, ListPJobsResponse, ListPJobsResults,
} from './job-board/list-personalized-jobs/list-p-jobs.service.types';
import { ListPJobsTransform } from './job-board/list-personalized-jobs/list-p-jobs.transform';
import { JobStatusRequest } from './job-board/update-job-status/job-status.service';
import {
  JobStatusService, JobStatusOptions, JobStatusResponse, JobStatusResults,
} from './job-board/update-job-status/job-status.service.types';
import { JobStatusTransform } from './job-board/update-job-status/job-status.transform';
import { ListAcadRequest } from './meta-data/list-acad/list-acad.service';
import {
  ListAcadService, ListAcadOptions, ListAcadResponse, ListAcadResults,
} from './meta-data/list-acad/list-acad.service.types';
import { ListAcadTransform } from './meta-data/list-acad/list-acad.transform';
import { ListCityRequest } from './meta-data/list-city-loc/list-city.service';
import {
  ListCityService, ListCityOptions, ListCityResponse, ListCityResults,
} from './meta-data/list-city-loc/list-city.service.types';
import { ListCityTransform } from './meta-data/list-city-loc/list-city.transform';
import { ListOrgRequest } from './meta-data/list-org/list-org.service';
import {
  ListOrgService, ListOrgOptions, ListOrgResponse, ListOrgResults,
} from './meta-data/list-org/list-org.service.types';
import { ListOrgTransform } from './meta-data/list-org/list-org.transform';
import { ListSkillRequest } from './meta-data/list-skills/list-skill.service';
import {
  ListSkillService, ListSkillOptions, ListSkillResponse, ListSkillResults,
} from './meta-data/list-skills/list-skill.service.types';
import { ListSkillTransform } from './meta-data/list-skills/list-skill.transform';
import { ListStrmRequest } from './meta-data/list-stream/list-stream.service';
import {
  ListStrmService, ListStrmOptions, ListStrmResponse, ListStrmResults,
} from './meta-data/list-stream/list-stream.service.types';
import { ListStrmTransform } from './meta-data/list-stream/list-stream.transform';
import { RegisterCMPSPostRequest } from './register-campus-user-by-cx/register.service';
import {
  RegisterCMPSService, RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults,
} from './register-campus-user-by-cx/register.service.types';
import { RegisterCMPSTransform } from './register-campus-user-by-cx/register.transform';
import { ResumeProfileRequest } from './resume-data/get-cand-profile/get-profile.service';
import {
  ResumeProfileService, ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults,
} from './resume-data/get-cand-profile/get-profile.service.types';
import { ResumeProfileTransform } from './resume-data/get-cand-profile/get-profile.transform';
import { ResumeObjRequest } from './resume-data/objective/get-obj/get-obj.service';
import {
  ResumeObjService, ResumeObjOptions, ResumeObjResponse, ResumeObjResults,
} from './resume-data/objective/get-obj/get-obj.service.types';
import { ResumeObjTransform } from './resume-data/objective/get-obj/get-obj.transform';
import { CampusObjectivePutRequest } from './resume-data/objective/update-obj/objective.service';
import {
  ObjectiveService, ObjectiveOptions, ObjectiveResponse, ObjectiveResults,
} from './resume-data/objective/update-obj/objective.service.types';
import { ObjectiveTransform } from './resume-data/objective/update-obj/objective.transform';
import { DelOrgProjPutRequest } from './resume-data/org-project/delete-org-proj/del-org-proj.service';
import {
  DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults, DelOrgProjService,
} from './resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { DelOrgProjTransform } from './resume-data/org-project/delete-org-proj/del-org-proj.transform';
import { ListOrgProjRequest } from './resume-data/org-project/get-all-org-obj/list-org-proj.service';
import {
  ListOrgProjService, ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults,
} from './resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { ListOrgProjTransform } from './resume-data/org-project/get-all-org-obj/list-org-proj.transform';
import { CampusOrgProjPutRequest } from './resume-data/org-project/update-org-obj/org-proj.service';
import {
  OrgProjService, OrgProjOptions, OrgProjResponse, OrgProjResults,
} from './resume-data/org-project/update-org-obj/org-proj.service.types';
import { OrgProjTransform } from './resume-data/org-project/update-org-obj/org-proj.transform';
import { ResumePreviewRequest } from './resume-data/preview/get-preview.service';
import {
  ResumePreviewService, ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults,
} from './resume-data/preview/get-preview.service.types';
import { ResumePreviewTransform } from './resume-data/preview/get-preview.transform';
import { createService } from './service';
import { PassKeyPutRequest } from './update-passkey/update-passkey.service';
import {
  PassKeyPutService, PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults,
} from './update-passkey/update-passkey.service.types';
import { PassKeyPutTransform } from './update-passkey/update-passkey.transform';
import { UserProfilePutRequest } from './update-profile/update-profile.service';
import {
  UserProfilePutService, UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults,
} from './update-profile/update-profile.service.types';
import { UserProfilePutTransform } from './update-profile/update-profile.transform';
import { UploadImageRequest } from './upload-image/upload-image.service';
import {
  UploadImageService, UploadImageOptions, UploadImageResponse, UploadImageResults,
} from './upload-image/upload-image.service.types';
import { UploadImageTransform } from './upload-image/upload-image.transform';
import { UploadVideoRequest } from './upload-video/upload-video.service';
import {
  UploadVideoService, UploadVideoOptions, UploadVideoResponse, UploadVideoResults,
} from './upload-video/upload-video.service.types';
import { UploadVideoTransform } from './upload-video/upload-video.transform';
import { AcadAddRequest } from './meta-data/add-academy/add-new-acad.service';
import {
  AcadAddService, AcadAddOptions, AcadAddResponse, AcadAddResults,
} from './meta-data/add-academy/add-new-acad.service.types';
import { AcadAddTransform } from './meta-data/add-academy/add-new-acad.transform';
import {
  ListUnivOptions, ListUnivResponse, ListUnivResults, ListUnivService,
} from './meta-data/list-university/list-univ.service.types';
import { ListUnivRequest } from './meta-data/list-university/list-univ.service';
import { ListUnivTransform } from './meta-data/list-university/list-univ.transform';
import { ListMentorRequest } from './mentors/mentor-list/list-mentor.service';
import {
  ListMentorService, ListMentorOptions, ListMentorResponse, ListMentorResults,
} from './mentors/mentor-list/list-mentor.service.types';
import { ListMentorTransform } from './mentors/mentor-list/list-mentor.transform';
import { CampusMentorAccessUpdatePutRequest } from './mentors/mentor-access-update/mentor-update.service';
import {
  MentorAccessUpdateService, MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults,
} from './mentors/mentor-access-update/mentor-update.service.types';
import { MentorAccessUpdateTransform } from './mentors/mentor-access-update/mentor-update.transform';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const acadMetaListService: ListAcadService = createService<ListAcadOptions, ListAcadResponse, ListAcadResults>(ListAcadRequest, ListAcadTransform);
export const registerCMPSService: RegisterCMPSService = createService<RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults>(RegisterCMPSPostRequest, RegisterCMPSTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const forgotPasswordService: ForgotPasswordService = createService<ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults>(ForgotPasswordRequest, ForgotPasswordTransform);
export const fileURLService: FileURLService = createService<FileURLOptions, FileURLResponse, FileURLResults>(FileURLRequest, FileURLTransform);
export const cityMetaListService: ListCityService = createService<ListCityOptions, ListCityResponse, ListCityResults>(ListCityRequest, ListCityTransform);
export const userProfilePutService: UserProfilePutService = createService<UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults>(UserProfilePutRequest, UserProfilePutTransform);
export const userProfilePicService: UploadImageService = createService<UploadImageOptions, UploadImageResponse, UploadImageResults>(UploadImageRequest, UploadImageTransform);
export const passKeyService: PassKeyPutService = createService<PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults>(PassKeyPutRequest, PassKeyPutTransform);
export const orgMetaListService: ListOrgService = createService<ListOrgOptions, ListOrgResponse, ListOrgResults>(ListOrgRequest, ListOrgTransform);
export const skillMetaListService: ListSkillService = createService<ListSkillOptions, ListSkillResponse, ListSkillResults>(ListSkillRequest, ListSkillTransform);
export const resumeProfileService: ResumeProfileService = createService<ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults>(ResumeProfileRequest, ResumeProfileTransform);
export const getResumeObjService: ResumeObjService = createService<ResumeObjOptions, ResumeObjResponse, ResumeObjResults>(ResumeObjRequest, ResumeObjTransform);
export const objectiveService: ObjectiveService = createService<ObjectiveOptions, ObjectiveResponse, ObjectiveResults>(CampusObjectivePutRequest, ObjectiveTransform);
export const listOrgProjService: ListOrgProjService = createService<ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults>(ListOrgProjRequest, ListOrgProjTransform);
export const listPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const jobStatusService: JobStatusService = createService<JobStatusOptions, JobStatusResponse, JobStatusResults>(JobStatusRequest, JobStatusTransform);
export const listAPLPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const listSVDPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const listJobsService: ListJobsService = createService<ListJobsOptions, ListJobsResponse, ListJobsResults>(ListJobsRequest, ListJobsTransform);
export const jobPositionByIdsService: JobPositionByIdService = createService<JobPositionByIdOptions, JobPositionByIdResponse, JobPositionByIdResults>(JobPositionByIdRequest, JobPositionByIdTransform);
export const orgProjService: OrgProjService = createService<OrgProjOptions, OrgProjResponse, OrgProjResults>(CampusOrgProjPutRequest, OrgProjTransform);
export const deleteOrgProjService: DelOrgProjService = createService<DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults>(DelOrgProjPutRequest, DelOrgProjTransform);
export const getResumePreviewService: ResumePreviewService = createService<ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults>(ResumePreviewRequest, ResumePreviewTransform);
export const userProfileVideoService: UploadVideoService = createService<UploadVideoOptions, UploadVideoResponse, UploadVideoResults>(UploadVideoRequest, UploadVideoTransform);
export const videoURLService: VideoURLService = createService<VideoURLOptions, VideoURLResponse, VideoURLResults>(VideoURLRequest, VideoURLTransform);
export const strmMetaListService: ListStrmService = createService<ListStrmOptions, ListStrmResponse, ListStrmResults>(ListStrmRequest, ListStrmTransform);
export const batchService: BatchPutService = createService<BatchPutOptions, BatchPutResponse, BatchPutResults>(BatchPutRequest, BatchPutTransform);
export const batchListService: ListBatchService = createService<ListBatchOptions, ListBatchResponse, ListBatchResults>(ListBatchRequest, ListBatchTransform);
export const candUpdtService: CandPutService = createService<CandPutOptions, CandPutResponse, CandPutResults>(CandPutRequest, CandPutTransform);
export const pCandListService: ListPCandService = createService<ListPCandOptions, ListPCandResponse, ListPCandResults>(ListPCandRequest, ListPCandTransform);
export const addAcadService: AcadAddService = createService<AcadAddOptions, AcadAddResponse, AcadAddResults>(AcadAddRequest, AcadAddTransform);
export const univListService: ListUnivService = createService<ListUnivOptions, ListUnivResponse, ListUnivResults>(ListUnivRequest, ListUnivTransform);
export const mentorListService: ListMentorService = createService<ListMentorOptions, ListMentorResponse, ListMentorResults>(ListMentorRequest, ListMentorTransform);
export const mentorAccessService: MentorAccessUpdateService = createService<MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults>(CampusMentorAccessUpdatePutRequest, MentorAccessUpdateTransform);
