import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import './avatar.scss';
import {
  Button, Card, CardBody, CardFooter, CardHeader, CardImg, Col, Form, Input, Row,
} from 'reactstrap';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadImageDataInput } from '../../../../services/upload-image/upload-image.types';
import { getTokenFromLocalStorage, setProfileUrlStorage } from '../../../../utils/service/localstorage-service';
import { getFileURLReq, uploadImageReq } from '../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../utils/alert';
import { UserType, APIParam, APIHeader } from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';

export const MyAvatar: React.FC = () => {
  const dispatch = useDispatch();
  const generatedToken = getTokenFromLocalStorage();
  const [isNotLoadFile, setIsNotLoadFile] = useState(true);
  const [fileSizeAlert, setFileSizeAlert] = useState(false);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const uploadImageResponse = useSelector((state: RootState) => state.campusXcel.updateProfileImage);
  const fileUrlResponse = useSelector((state: RootState) => state.campusXcel.updateFileURL);
  const [filePreview, setFilePreview] = useState('/images/common/avatar.png');
  const newData = new FormData();
  const [campusprofilePhotoForm, setCampusprofilePhotoForm] = useState<UploadImageDataInput>({
    fileData: new FormData(),
  });
  // console.log('get file', campusprofilePhotoForm);
  // const handleCloseAlertFileSize = () => {
  //   setFileSizeAlert(false);
  // };
  const handleprofilephoto = (e:any) => {
    if (e.target.files[0].size < 1000000) {
      newData.append('file', e.target.files[0]);
      setCampusprofilePhotoForm({ ...campusprofilePhotoForm, [e.target.name]: newData });
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setFilePreview(objectUrl);
      setIsNotLoadFile(false);
      setFileSizeAlert(false);
    } else {
      setFileSizeAlert(true);
      setFilePreview('/images/common/avatar.png');
      setIsNotLoadFile(true);
    }
  };

  const handleSubmitFileUpload = (e: any) => {
    e.preventDefault();
    setLoaderProfile(true);
    dispatch(uploadImageReq({
      inputBody: campusprofilePhotoForm.fileData,
      requestType: APIHeader.REQ_UPLOAD_FILES,
      userReqFor: UserType.CMPS,
      uploadTp: APIParam.AVATAR_IMAGE,
      requestData: APIHeader.REQ_FORM_DATA,
      requestFile: APIHeader.REQ_IMG_DATA,
      userType: UserType.COMMN,
      token: generatedToken,
    }));
  };
  React.useEffect(() => {
    if (uploadImageResponse.error && uploadImageResponse.message !== '') {
      setLoaderProfile(false);
      setAlertMsg(uploadImageResponse.message);
      setErrorShowAlert(true);
    }
  }, [uploadImageResponse.error, uploadImageResponse.message]);

  React.useEffect(() => {
    if (!uploadImageResponse.error && uploadImageResponse.message !== '') {
      setLoaderProfile(false);
      dispatch(getFileURLReq({
        requestType: APIHeader.REQ_FILE_URL,
        userReqFor: UserType.CMPS,
        bucketTp: APIParam.AVATAR_IMAGE,
        userType: UserType.COMMN,
        token: generatedToken,
      }));
    }
  }, [uploadImageResponse.error, uploadImageResponse.message]);

  React.useEffect(() => {
    if (fileUrlResponse.isError && fileUrlResponse.message !== '') {
      setLoaderProfile(false);
    }
  }, [fileUrlResponse.isError, fileUrlResponse.message]);

  React.useEffect(() => {
    if (!fileUrlResponse.isError && fileUrlResponse.url !== '') {
      setLoaderProfile(false);
      setProfileUrlStorage(JSON.stringify(fileUrlResponse.url));
    }
  }, [fileUrlResponse.isError, fileUrlResponse.url]);

  return (
    <Row className="pt-3">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 6, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
        <Form onSubmit={handleSubmitFileUpload}>
          <Card className="form-card">
            <CardHeader className="form-card-header">
              Update your Avatar
            </CardHeader>
            <CardBody>
              {fileSizeAlert ? <span className="alert-error mx-2">File size should not exceed 1mb</span> : null}
              <Card className="collapse-bg form-card-border-none my-2">
                <div className="align-center">
                  <CardImg alt="logo" className="img-fluid rounded-circle avatar-user-profile-preview" src={filePreview} />
                </div>
                <div className="align-center">
                  <Input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="fileData"
                    className="profile-image-btn avatar"
                    onChange={(e) => handleprofilephoto(e)}
                  />
                </div>
              </Card>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end">
                <Button className="campus-button-oval mx-1" disabled={isNotLoadFile || loaderProfile}>
                  Upload
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <Row className="align-end">
          <Col xs="10">
            <Card className="form-card-shadow">
              <CardHeader className="card-header-border">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span>Do you Know ?</span>
              </CardHeader>
              <CardBody>
                Get the details here - Avatar
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MyAvatar;
