import { ListAcadResponse, ListAcadResults } from './list-acad.service.types';

export const ListAcadTransform: (response: ListAcadResponse) => ListAcadResults = (listAcadResults) => {
  const { data, is_error, message } = listAcadResults;
  const result: ListAcadResults = {
    tCnt: 0,
    fCnt: 0,
    acadData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.acadData = data.acadData;
  }
  return result;
};
