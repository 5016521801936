import {
  faSearch, faFilter, faCaretDown, faCaretUp, faRefresh, faEnvelope, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  Row, Col, Button, Input, InputGroup, Card, CardBody, CardHeader, UncontrolledTooltip, CardFooter, Modal, ModalBody,
} from 'reactstrap';
import { PCandData } from '../../../../services/candidates/pending-approval-list/list-pcand.types';
import { getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import {
  candReq, clearCandListReq, clearCandReq, getPCandListReq,
} from '../../../../store/campusXcel/actions';
import { UserType, APIHeader, ApiLIMIT } from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';
import { errorAlert } from '../../../../utils/alert';
import { CandPutDataInput } from '../../../../services/candidates/update-candidate/update-cand.types';

export const ListPendingCandidates: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [activeName, setActiveName] = useState('filterBatch');
  const [loaderCand, setLoaderCand] = React.useState(false);
  // const [isSentSucc, setIsSentSucc] = React.useState(false);
  // const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [isApproveConfirmed, setIsApproveConfirmed] = useState(false);
  const [isRejectConfirmed, setIsRejectConfirmed] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [modalConfirmApprove, setModalConfirmApprove] = useState(false);
  const [modalConfirmReject, setModalConfirmReject] = useState(false);
  const pCandData = useSelector((state: RootState) => state.campusXcel.getPCandList);
  const pCandResponse = useSelector((state: RootState) => state.campusXcel.updateCand);
  const [pendingCand, setPendingCand] = useState<CandPutDataInput>({
    isAcs: false,
    lgnSts: '',
    userCd: '',
  });
  const [candPData, setCandPData] = React.useState<PCandData>({
    tCnt: 0,
    fCnt: -1,
    message: '',
    cndData: [],
  });

  const handlePCandRefresh = () => {
    Promise.resolve(dispatch(clearCandListReq()))
      .then(() => {
        setLoaderCand(true);
        dispatch(getPCandListReq({
          userType: UserType.CMPS,
          requestType: APIHeader.REQ_GET_ALL_PENDING_CAND,
          limit: ApiLIMIT.LIMIT,
          token: tokenData,
          searchData: '',
        }));
      });
  };

  // React.useEffect(() => {
  //   setLoaderCand(true);
  //   dispatch(getPCandListReq({
  //     userType: UserType.CMPS,
  //     requestType: APIHeader.REQ_GET_ALL_PENDING_CAND,
  //     limit: ApiLIMIT.LIMIT,
  //     token: tokenData,
  //     searchData: '',
  //   }));
  // }, []);

  React.useEffect(() => {
    if (pCandData.isError && pCandData.message !== '') {
      setLoaderCand(false);
      setAlertMsg(pCandData.message);
      setErrorShowAlert(true);
    }
  }, [pCandData.isError, pCandData.message]);

  React.useEffect(() => {
    if (!pCandData.isError && pCandData.message !== '') {
      setLoaderCand(false);
      setCandPData(pCandData);
      // Promise.resolve(setCandPData(pCandData))
      //   .then(() => {
      //     dispatch(clearCandReq());
      //   });
    }
  }, [pCandData.isError, pCandData.message]);

  const handleCollapseClick = (activeString: any) => {
    setActiveName(activeString);
  };

  const handleConfApprove = (userCode: string) => {
    setPendingCand({ ...pendingCand, userCd: userCode });
    setModalConfirmApprove(true);
  };

  const handleConfReject = (userCode: string) => {
    setPendingCand({ ...pendingCand, userCd: userCode });
    setModalConfirmReject(true);
  };

  const handleConfirmApprove = (status: string) => {
    if (status === 'approve') {
      // setIsSentSucc(true);
      setPendingCand({
        ...pendingCand, isAcs: true, lgnSts: 'APPR',
      });
      setIsApproveConfirmed(true);
    } else {
      setIsApproveConfirmed(false);
    }
    setModalConfirmApprove(false);
  };

  const handleConfirmReject = (status: string) => {
    if (status === 'reject') {
      // setIsSentSucc(true);
      setPendingCand({
        ...pendingCand, isAcs: false, lgnSts: 'NACC',
      });
      setIsRejectConfirmed(true);
    } else {
      setIsRejectConfirmed(false);
    }
    setModalConfirmReject(false);
  };
  React.useEffect(() => {
    if (isApproveConfirmed) {
      setLoaderCand(true);
      dispatch(candReq({
        inputBody: pendingCand,
        requestType: APIHeader.REQ_UPDATE_PENDING_CAND,
        userType: UserType.CMPS,
        token: tokenData,
      }));
    }
  }, [isApproveConfirmed]);

  React.useEffect(() => {
    if (isRejectConfirmed) {
      setLoaderCand(true);
      dispatch(candReq({
        inputBody: pendingCand,
        requestType: APIHeader.REQ_UPDATE_PENDING_CAND,
        userType: UserType.CMPS,
        token: tokenData,
      }));
    }
  }, [isRejectConfirmed]);

  React.useEffect(() => {
    if (pCandResponse.error && pCandResponse.message !== '') {
      setLoaderCand(false);
      // setIsSentSucc(false);
      setAlertMsg(pCandResponse.message);
      setErrorShowAlert(true);
    }
  }, [pCandResponse.error, pCandResponse.message]);

  React.useEffect(() => {
    if (!pCandResponse.error && pCandResponse.candData !== '') {
      setLoaderCand(false);
      Promise.resolve(dispatch(clearCandReq()))
        .then(() => {
          dispatch(getPCandListReq({
            userType: UserType.CMPS,
            requestType: APIHeader.REQ_GET_ALL_PENDING_CAND,
            limit: ApiLIMIT.LIMIT,
            token: tokenData,
            searchData: '',
          }));
        });
    }
  }, [pCandResponse.error, pCandResponse.candData]);

  return (
    <Row>
      { loaderCand ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="pt-3">
        <Col lg={{ size: 5, order: 1 }} md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
          <InputGroup>
            <Input className="campus-input-search" />
            <Button className="campus-input-search-icon mx-2">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </Col>
        <Col lg={{ size: 1, order: 2 }} md={{ size: 2, order: 2 }} xs={{ order: 1 }}>
          <div className="align-start my-2">
            <Button className="button-icon-none mx-2" id="refreshPCand" onClick={handlePCandRefresh}>
              <FontAwesomeIcon icon={faRefresh} className="refresh-icon" />
            </Button>
            <UncontrolledTooltip delay={0} target="refreshPCand">
              Refresh to load page
            </UncontrolledTooltip>
          </div>
        </Col>
      </Row>
      <Col lg={{ size: 9, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 2 }}>
        <Row>
          <Col xs="12">
            {candPData && candPData.cndData && candPData.cndData.map((cand, index) => (
              <Card className="form-card-shadow my-3" key={`cand-${index.toString()}`}>
                <CardHeader className="form-card-header">
                  <Row>
                    <Col lg="8" xs="6">
                      <div className="my-2">
                        <span className="form-card-header-text">{cand.fNme}{' '}{cand.lNme}</span>
                      </div>
                    </Col>
                    <Col lg="4" xs="6">
                      <div className="my-2">
                        <span className="quest-menu-icon-size-sm">{cand.bCd}</span>
                      </div>
                    </Col>
                    <Col lg="5" xs="12">
                      <div>
                        <FontAwesomeIcon icon={faEnvelope} className="quest-menu-icon-size-sm mx-1" />
                        <span className="input-checkbox-text-grey">{cand.eId}</span>
                      </div>
                    </Col>
                    <Col lg="3" xs="12">
                      <div>
                        <FontAwesomeIcon icon={faPhone} className="quest-menu-icon-size-sm mx-1" />
                        <span className="input-checkbox-text-grey">{cand.ph}</span>
                      </div>
                    </Col>
                    <Col lg="4" xs="12">
                      <div>
                        <span className="text-lite-grey">Enrollment#</span>
                        <span className="input-checkbox-text-grey">{cand.UserCd.substring(cand.UserCd.lastIndexOf('-') + 1)}</span>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <span className="text-lite-grey mx-1">Batch of :</span>
                  <span className="input-checkbox-text-grey">{cand.crsTp}</span>
                  <span className="text-lite-grey mx-1">in STREAM of</span>
                  <span className="input-checkbox-text-grey">{cand.strm}</span>
                  <span className="text-lite-grey mx-1">valid from</span>
                  <span className="input-checkbox-text-grey">{moment(cand.sDte).format('MMM-YYYY')}</span>
                  <span className="text-lite-grey mx-1">till</span>
                  <span className="input-checkbox-text-grey">{moment(cand.eDte).format('MMM-YYYY')}</span>
                </CardBody>
                <CardFooter className="form-card-footer">
                  <div className="align-end">
                    <Button className="campus-button-oval mx-1" onClick={() => handleConfReject(cand.UserCd)}>
                      Reject
                    </Button>
                    <Button className="campus-button-oval mx-1" onClick={() => handleConfApprove(cand.UserCd)}>
                      Approve
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </Col>
        </Row>
      </Col>
      <Col lg={{ size: 3, order: 2 }} md={{ size: 4, order: 2 }} xs={{ order: 1 }}>
        <div className="">
          <Card className="form-card my-3">
            <CardHeader className="form-collapse-card-header cursor-pointer">
              <Row>
                <Col lg="10" md="10" xs="10">
                  <FontAwesomeIcon icon={faFilter} className="collapse-icon" />
                  <span className="campus-filter-header mx-1">Filter</span>
                </Col>
                <Col lg="2" md="2" xs="2">
                  {activeName === 'filterBatch'
                    ? (
                      <Button onClick={() => handleCollapseClick('none')} className="button-icon-none">
                        <FontAwesomeIcon className="collapse-icon" icon={faCaretUp} />
                      </Button>
                    )
                    : (
                      <Button onClick={() => handleCollapseClick('filterBatch')} className="button-icon-none">
                        <FontAwesomeIcon className="collapse-icon" icon={faCaretDown} />
                      </Button>
                    ) }
                </Col>
              </Row>
            </CardHeader>
            <Collapsible
              open={activeName === 'filterBatch'}
              trigger={activeName ? '' : ''}
              onOpening={() => setActiveName('filterBatch')}
            >
              <CardBody>
                <div>
                  Filter Data
                </div>
                <div className="align-center pt-2">
                  <Button className="campus-button-oval">
                    Clear All
                  </Button>
                </div>
              </CardBody>
            </Collapsible>
          </Card>
        </div>
      </Col>
      <Modal isOpen={modalConfirmApprove}>
        <ModalBody>
          <span className="mx-2 input-checkbox-text-grey">Do you really want to delete ?</span>
          <div className="align-end pt-3">
            <Button className="campus-button-oval mx-1" onClick={() => handleConfirmApprove('cancel')}>
              cancel
            </Button>
            <Button className="campus-button-oval mx-1" onClick={() => handleConfirmApprove('approve')}>
              Approve
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConfirmReject}>
        <ModalBody>
          <span className="mx-2 input-checkbox-text-grey">Do you really want to delete ?</span>
          <div className="align-end pt-3">
            <Button className="campus-button-oval mx-1" onClick={() => handleConfirmReject('cancel')}>
              cancel
            </Button>
            <Button className="campus-button-oval mx-1" onClick={() => handleConfirmReject('reject')}>
              Reject
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );
};
export default ListPendingCandidates;
