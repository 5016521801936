import {
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Row, Col, Button, UncontrolledTooltip, Card, CardBody, CardHeader,
} from 'reactstrap';
import { ListCollegeProps } from './college.type';
import ListColleges from './all-colleges/list-college.component';
import AddColleges from './add-colleges/add-college.component';

export const CollegeDetails: React.FC<ListCollegeProps> = ({ collegeListData }) => {
  const [isListCollege, setIsListCollege] = useState(true);
  return (
    <div className="pt-2">
      <Card className="form-card-border-none">
        <CardHeader className="form-card-header">
          <Row>
            <Col lg="9" md="8" xs="6">
              Academies
            </Col>
            <Col lg="3" md="4" xs="6">
              <div className="align-end">
                {isListCollege
                  ? (
                    <Button className="campus-button-plain align-center pt-1" id="addNewId" onClick={() => setIsListCollege(false)}>
                      Onboard Campus
                    </Button>
                  ) : null}
                {!isListCollege
                  ? (
                    <>
                      <Button className="button-icon align-center" id="listProjId" onClick={() => setIsListCollege(true)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                      <UncontrolledTooltip delay={0} target="listProjId">
                        Go Back
                      </UncontrolledTooltip>
                    </>
                  ) : null}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {isListCollege ? (
              <div>
                <ListColleges collegeListData={collegeListData} />
              </div>
            )
              : (
                <div>
                  <AddColleges />
                </div>
              )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
