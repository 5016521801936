import { ObjectiveResponse, ObjectiveResults } from './objective.service.types';

export const ObjectiveTransform: (response:ObjectiveResponse) => ObjectiveResults = (objectiveResults) => {
  const { data, is_error, message } = objectiveResults;
  const result : ObjectiveResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
