import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Collapsible from 'react-collapsible';
import { PassKeyPutDataInput } from '../../../../services/update-passkey/update-passkey.types';
import { getTokenFromLocalStorage, setUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { clearPassKeyReq, passKeyReq } from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';

export const PasskeyUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const generatedToken = getTokenFromLocalStorage();
  const [matchPassKey, setMatchPassKey] = useState(false);
  const [disableText, setDisableText] = useState(true);
  const [activeName, setActiveName] = useState('passk');
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const loginProfileData = useSelector((state: RootState) => state.campusXcel.profileData);
  const passKeyResponse = useSelector((state: RootState) => state.campusXcel.updatePassKey);
  const [myPassKey, setMyPassKey] = useState<PassKeyPutDataInput>({
    psKey: '',
    prePsKey: '',
  });
  const [confirmPassKey, setConfirmPassKey] = useState(
    {
      confirmKey: '',
    },
  );

  const handleCollapseClick = (activeString: any) => {
    setActiveName(activeString);
  };

  const handleMyPassKey = (e:any) => {
    setMyPassKey({ ...myPassKey, [e.target.name]: e.target.value });
  };

  const handleConfirmPassKey = (e:any) => {
    setConfirmPassKey({ ...confirmPassKey, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (loginTokenData.token !== '' && !loginProfileData.profileData.isPKReset) {
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
    }
  }, [loginProfileData]);

  React.useEffect(() => {
    if ((confirmPassKey.confirmKey === myPassKey.psKey) && (confirmPassKey.confirmKey !== '' && myPassKey.psKey !== '')) {
      setMatchPassKey(true);
      setDisableText(false);
    } else {
      setMatchPassKey(false);
    }
  }, [confirmPassKey.confirmKey, myPassKey.psKey]);

  const handleSubmitPassKey = (e: any) => {
    e.preventDefault();
    setLoaderProfile(true);
    dispatch(passKeyReq({
      inputBody: myPassKey,
      userReqFor: UserType.CX,
      requestType: APIHeader.REQ_UPDATE_PASSWORD,
      userType: UserType.COMMN,
      token: generatedToken,
    }));
  };

  React.useEffect(() => {
    if (passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      setAlertMsg(passKeyResponse.message);
      setErrorShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  React.useEffect(() => {
    if (!passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      dispatch(clearPassKeyReq());
      setMyPassKey({
        psKey: '',
        prePsKey: '',
      });
      setConfirmPassKey({
        confirmKey: '',
      });
      setAlertMsg(passKeyResponse.message);
      setSuccessShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  return (
    <Row className="pt-3">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 6, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
        <Form onSubmit={handleSubmitPassKey}>
          <Card className="form-card">
            <CardHeader className="form-card-header">
              Update your password
            </CardHeader>
            <CardBody>
              <Card className="collapse-bg form-card-border-none my-2">
                <CardHeader className="form-collapse-card-header cursor-pointer" onClick={() => handleCollapseClick('passk')}>
                  <Row>
                    <Col lg="11" md="10" xs="10">
                      Personal Details
                    </Col>
                    <Col lg="1" md="2" xs="2">
                      <FontAwesomeIcon className="collapse-icon" icon={activeName === 'passk' ? faCaretUp : faCaretDown} />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapsible
                // key={proj.prjNme}
                  open={activeName === 'passk'}
                  trigger={activeName ? '' : ''}
                  onOpening={() => setActiveName('passk')}
                >
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="input-header-text">Current Password<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Your Current Password"
                      type="password"
                      name="prePsKey"
                      value={myPassKey.prePsKey}
                      required
                      onChange={handleMyPassKey}
                      className="campus-input"
                    />
                  </div>
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="input-header-text">New Password<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Your New Password"
                      type="password"
                      name="psKey"
                      value={myPassKey.psKey}
                      required
                      onChange={handleMyPassKey}
                      className="campus-input"
                    />
                  </div>
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="input-header-text">Confirm Password<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Confirm your Password"
                      type="password"
                      name="confirmKey"
                      required
                      value={confirmPassKey.confirmKey}
                      onChange={handleConfirmPassKey}
                      className="campus-input"
                    />
                    {myPassKey.psKey !== '' && confirmPassKey.confirmKey !== '' ? <div className="float-right-passkey-campus mx-1"> {matchPassKey ? <span className="correct-pass-key">Password matched</span> : <span className="wrong-pass-key">Password mismatch</span>}</div> : null}
                  </div>
                </Collapsible>
              </Card>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end">
                <Button className="campus-button-oval mx-1" disabled={loaderProfile || !matchPassKey || disableText}>
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <Row className="align-end">
          <Col xs="10">
            <Card className="form-card-shadow">
              <CardHeader className="card-header-border">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span>Do you Know ?</span>
              </CardHeader>
              <CardBody>
                Get the details here - Passkey
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PasskeyUpdate;
