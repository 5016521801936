import { postJSON } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { BatchPutOptions, BatchPutResponse } from './update-batch.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();

export const BatchPutRequest = ({
  requestType, userType, inputBody, token,
}: BatchPutOptions): Promise<BatchPutResponse> => {
  if (token) {
    return postJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return postJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, '', requestType, inputBody as unknown as BodyInit);
};
