import {
  getCommonApi, getLogin, getToken, putCommonApi,
} from '../../utils';
import { getConfig } from '../../utils/config/config';
import { GenTokenOptions, GenTokenResponse } from './gen-token.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();

export const GenTokenRequest = ({
  userType, userName, userReqFor, requestType, passKey, isPublic,
}: GenTokenOptions): Promise<GenTokenResponse> => {
  const digits = passKey.match(/^\d{4}$/);
  if (!isPublic) {
    if (userName !== '' && passKey !== '') {
      if (digits !== null) {
        return getCommonApi(`${CAMPUSXCEL_API_ROOT}?userOtp=${passKey}&userType=${userType}&userReqFor=${userReqFor}&userInfo=${userName}`, requestType);
      }
      return getLogin(`${CAMPUSXCEL_API_ROOT}?userType=${userType}&userReqFor=${userReqFor}`, requestType, userName, passKey);
    }
    if (userName !== '' && passKey === '') {
      return putCommonApi(`${CAMPUSXCEL_API_ROOT}?userType=${userType}&userReqFor=${userReqFor}&userInfo=${userName}`, requestType);
    }
  }
  return getToken(`${CAMPUSXCEL_API_ROOT}?userType=${userType}&userReqFor=${userReqFor}`, requestType);
};
