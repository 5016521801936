import { DelOrgProjResponse, DelOrgProjResults } from './del-org-proj.service.types';

export const DelOrgProjTransform: (response:DelOrgProjResponse) => DelOrgProjResults = (objectiveResults) => {
  const { data, is_error, message } = objectiveResults;
  const result : DelOrgProjResults = {
    message: '',
    error: false,
    errorMessage: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
