import { ListBatchResponse, ListBatchResults } from './list-batch.service.types';

export const ListBatchTransform: (response: ListBatchResponse) => ListBatchResults = (listBatchResults) => {
  const { data, is_error } = listBatchResults;
  const result: ListBatchResults = {
    tCnt: 0,
    fCnt: 0,
    acadData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = data.message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.acadData = data.acadData;
  }
  return result;
};
