import {
  Col, Row,
} from 'reactstrap';
import './dashboard.scss';

export const CandidateDashboard: React.FC = () => (
  <Row className="mx-3">
    <Col lg="12">
      <div className="">
        <span className="display-6 text-intro-sub-header">
          Dashboard
        </span>
        <div className="pt-5 align-text-justify">
          <span className="text-about pt-5">
            In Progress
          </span>
        </div>
      </div>
    </Col>
  </Row>
);

export default CandidateDashboard;
