import React from 'react';
import { faIdCard, faPhotoFilm, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import './profile.scss';
import AboutMe from './about-me/about-me.component';
import { PasskeyUpdate } from './passkey-reset/passkey.component';
import { MyAvatar } from './my-avatar/my-avatar.component';

export const MyProfile: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('abtMe');
  return (
    <Row>
      <Col xs="12">
        <Nav tabs className="campus-profile-tab">
          <div className="campus-row-profile-tab">
            <div className="campus-column-profile-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-profile-nav-tabs" active={activeTab === 'abtMe'} onClick={() => setActiveTab('abtMe')}>
                  <FontAwesomeIcon icon={faIdCard} className="campus-tab-profile-icon" />
                  <span> About Me</span>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-profile-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-profile-nav-tabs" active={activeTab === 'passKey'} onClick={() => setActiveTab('passKey')}>
                  <FontAwesomeIcon icon={faUserLock} className="campus-tab-profile-icon" />
                  <span className="campus-tab-text">Password</span>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-profile-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-profile-nav-tabs" active={activeTab === 'myAvatar'} onClick={() => setActiveTab('myAvatar')}>
                  <FontAwesomeIcon icon={faPhotoFilm} className="campus-tab-profile-icon" />
                  <span className="campus-tab-text">My Avatar</span>
                </NavLink>
              </NavItem>
            </div>
          </div>
          <div className="campus-row-profile-tab-mobile">
            <div className="campus-column-profile-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink id="abtMeId" className="campus-profile-nav-tabs" active={activeTab === 'abtMe'} onClick={() => setActiveTab('abtMe')}>
                  <FontAwesomeIcon icon={faIdCard} className="campus-tab-profile-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="abtMeId">
                  About Me
                </UncontrolledTooltip>
              </NavItem>
            </div>
            <div className="campus-column-profile-tab-right">
              <NavItem className="profile-pointer-campus">
                <NavLink id="passKeyId" className="campus-profile-nav-tabs" active={activeTab === 'passKey'} onClick={() => setActiveTab('passKey')}>
                  <FontAwesomeIcon icon={faUserLock} className="campus-tab-profile-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="passKeyId">
                  Password
                </UncontrolledTooltip>
              </NavItem>
            </div>
            <div className="campus-column-profile-tab-right">
              <NavItem className="profile-pointer-campus">
                <NavLink id="avatarId" className="campus-profile-nav-tabs" active={activeTab === 'myAvatar'} onClick={() => setActiveTab('myAvatar')}>
                  <FontAwesomeIcon icon={faPhotoFilm} className="campus-tab-profile-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="avatarId">
                  My Avatar
                </UncontrolledTooltip>
              </NavItem>
            </div>
          </div>
        </Nav>
      </Col>
      <Col xs="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="abtMe">
            <AboutMe />
          </TabPane>
          <TabPane tabId="myAvatar">
            <MyAvatar />
          </TabPane>
          <TabPane tabId="passKey">
            <PasskeyUpdate />
          </TabPane>
        </TabContent>
      </Col>
      <Col xs="12">
        More adv
      </Col>
    </Row>
  );
};

export default MyProfile;
