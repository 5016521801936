import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactUsComponent from '../../scenes/public/contactus.component';
import LoginComponent from '../../scenes/public/login.component';
import { CampusXcelPrivateAdminComponent } from '../../scenes/exclusive/campusXcel.component.admin';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/" element={<LoginComponent />} />
    <Route path="/contact-us" element={<ContactUsComponent />} />
    <Route path="/login" element={<LoginComponent />} />
    <Route path="/cx/admin/*" element={<CampusXcelPrivateAdminComponent />} />
  </Routes>
);

export default AppBase;
