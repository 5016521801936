import { OrgProjResponse, OrgProjResults } from './org-proj.service.types';

export const OrgProjTransform: (response:OrgProjResponse) => OrgProjResults = (objectiveResults) => {
  const { data, is_error, message } = objectiveResults;
  const result : OrgProjResults = {
    message: '',
    error: false,
    errorMessage: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
