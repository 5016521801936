import { createAction } from 'typesafe-actions';
import {
  ADD_ACAD_DATA,
  API_ERROR, BATCH_DATA, CAND_DATA, CLEAR_ADD_ACAD_DATA, CLEAR_BATCH_DATA, CLEAR_CAND_DATA, CLEAR_CAND_LIST_DATA, CLEAR_CITY_LIST, CLEAR_CONTACT_US_DATA, CLEAR_JOB_POSITION_BY_ID, CLEAR_JOB_STATUS_DATA, CLEAR_MENTOR_ACCESS, CLEAR_MNTR_STS_LIST_DATA, CLEAR_OBJECTIVE, CLEAR_ORG_ORG_PROJ, CLEAR_PASS_KEY_DATA, CLEAR_REGISTER_CMPS_DATA, CLEAR_UNIV_LIST_DATA, CLEAR_UPLOAD_IMAGE_DATA, CLEAR_UPLOAD_VIDEO_DATA, CLEAR_USER_PROFILE_PUT_DATA, CONTACT_US_DATA, DELETE_ORG_PROJ, GET_ACAD_LIST, GET_ALL_JOBS, GET_ALL_P_JOBS, GET_APL_JOBS, GET_BATCH_LIST, GET_CITY_LIST, GET_FILE_URL, GET_FORGOT_PASSWORD, GET_JOB_POSITION_BY_ID, GET_JOB_STATUS, GET_LIST_ORG_PROJ, GET_LOGIN_TOKEN, GET_MENTOR_ACCESS, GET_MNTR_STS_LIST, GET_OBJECTIVE, GET_ORG_LIST, GET_ORG_PROJ, GET_PCAND_LIST, GET_PREVIEW_RESUME, GET_PROFILE, GET_RESUME_OBJ, GET_RESUME_PROFILE, GET_SKILL_LIST, GET_STRM_LIST, GET_SVD_JOBS, GET_UNIV_LIST, GET_VIDEO_URL, PASS_KEY_DATA, REGISTER_CMPS_DATA, SHOW_DATA, UPDATE_ADD_ACAD_DATA, UPDATE_ALL_JOBS, UPDATE_ALL_P_JOBS, UPDATE_APL_JOBS, UPDATE_BATCH_DATA, UPDATE_BATCH_LIST, UPDATE_CAND_DATA, UPDATE_CITY_LIST, UPDATE_CONTACT_US_DATA, UPDATE_DATA, UPDATE_DELETE_ORG_PROJ, UPDATE_FORGOT_PASSWORD, UPDATE_GET_ACAD_LIST, UPDATE_GET_FILE_URL, UPDATE_GET_ORG_LIST, UPDATE_GET_SKILL_LIST, UPDATE_GET_VIDEO_URL, UPDATE_JOB_POSITION_BY_ID, UPDATE_JOB_STATUS_DATA, UPDATE_LIST_ORG_PROJ, UPDATE_LOGIN_TOKEN, UPDATE_MENTOR_ACCESS, UPDATE_MNTR_STS_LIST, UPDATE_OBJECTIVE, UPDATE_ORG_PROJ, UPDATE_PASS_KEY_DATA, UPDATE_PCAND_LIST, UPDATE_PREVIEW_RESUME, UPDATE_PROFILE, UPDATE_REGISTER_CMPS_DATA, UPDATE_RESUME_OBJ, UPDATE_RESUME_PROFILE, UPDATE_STRM_LIST, UPDATE_SVD_JOBS, UPDATE_UNIV_LIST, UPDATE_UPLOAD_IMAGE_DATA, UPDATE_UPLOAD_VIDEO_DATA, UPDATE_USER_PROFILE_PUT_DATA, UPLOAD_IMAGE_DATA, UPLOAD_VIDEO_DATA, USER_PROFILE_PUT_DATA,
} from './constants';
import { ContactUsOptions, ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ErrorAction } from '../../services/types';
import { ListAcadOptions, ListAcadResults } from '../../services/meta-data/list-acad/list-acad.service.types';
import { RegisterCMPSOptions, RegisterCMPSResults } from '../../services/register-campus-user-by-cx/register.service.types';
import { GenTokenOptions, GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileOptions, ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { ForgotPasswordOptions, ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { FileURLOptions, FileURLResults } from '../../services/get-file-url/get-file-url.service.types';
import { ListCityOptions, ListCityResults } from '../../services/meta-data/list-city-loc/list-city.service.types';
import { UserProfilePutOptions, UserProfilePutResults } from '../../services/update-profile/update-profile.service.types';
import { PassKeyPutOptions, PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { UploadImageOptions, UploadImageResults } from '../../services/upload-image/upload-image.service.types';
import { ListOrgOptions, ListOrgResults } from '../../services/meta-data/list-org/list-org.service.types';
import { ListSkillOptions, ListSkillResults } from '../../services/meta-data/list-skills/list-skill.service.types';
import { ResumeProfileOptions, ResumeProfileResults } from '../../services/resume-data/get-cand-profile/get-profile.service.types';
import { ResumeObjOptions, ResumeObjResults } from '../../services/resume-data/objective/get-obj/get-obj.service.types';
import { ObjectiveOptions, ObjectiveResults } from '../../services/resume-data/objective/update-obj/objective.service.types';
import { JobPositionByIdOptions, JobPositionByIdResults } from '../../services/job-board/job-position-by-id/job-position-by-id.service.types';
import { ListJobsOptions, ListJobsResults } from '../../services/job-board/list-jobs/list-jobs.service.types';
import { ListPJobsOptions, ListPJobsResults } from '../../services/job-board/list-personalized-jobs/list-p-jobs.service.types';
import { JobStatusOptions, JobStatusResults } from '../../services/job-board/update-job-status/job-status.service.types';
import { ListOrgProjOptions, ListOrgProjResults } from '../../services/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { OrgProjOptions, OrgProjResults } from '../../services/resume-data/org-project/update-org-obj/org-proj.service.types';
import { DelOrgProjOptions, DelOrgProjResults } from '../../services/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { ResumePreviewOptions, ResumePreviewResults } from '../../services/resume-data/preview/get-preview.service.types';
import { UploadVideoOptions, UploadVideoResults } from '../../services/upload-video/upload-video.service.types';
import { VideoURLOptions, VideoURLResults } from '../../services/get-video-url/get-video-url.service.types';
import { ListStrmOptions, ListStrmResults } from '../../services/meta-data/list-stream/list-stream.service.types';
import { BatchPutOptions, BatchPutResults } from '../../services/cmps-batches/add-batch/update-batch.service.types';
import { ListBatchOptions, ListBatchResults } from '../../services/cmps-batches/list-batch/list-batch.service.types';
import { CandPutOptions, CandPutResults } from '../../services/candidates/update-candidate/update-cand.service.types';
import { ListPCandOptions, ListPCandResults } from '../../services/candidates/pending-approval-list/list-pcand.service.types';
import { AcadAddOptions, AcadAddResults } from '../../services/meta-data/add-academy/add-new-acad.service.types';
import { ListUnivOptions, ListUnivResults } from '../../services/meta-data/list-university/list-univ.service.types';
import { ListMentorOptions, ListMentorResults } from '../../services/mentors/mentor-list/list-mentor.service.types';
import { MentorAccessUpdateOptions, MentorAccessUpdateResults } from '../../services/mentors/mentor-access-update/mentor-update.service.types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(UPDATE_CONTACT_US_DATA)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
export const getAcadListReq = createAction(GET_ACAD_LIST)<ListAcadOptions>();
export const updateAcadListReq = createAction(UPDATE_GET_ACAD_LIST)<ListAcadResults>();
export const registerCMPSReq = createAction(REGISTER_CMPS_DATA)<RegisterCMPSOptions>();
export const updateRegisterCMPSPostReq = createAction(UPDATE_REGISTER_CMPS_DATA)<RegisterCMPSResults>();
export const clearRegisterCMPSPostReq = createAction(CLEAR_REGISTER_CMPS_DATA)();
export const getLoginToken = createAction(GET_LOGIN_TOKEN)<GenTokenOptions>();
export const updateToken = createAction(UPDATE_LOGIN_TOKEN)<GenTokenResults>();
export const getProfile = createAction(GET_PROFILE)<ProfileOptions>();
export const updateProfile = createAction(UPDATE_PROFILE)<ProfileResults>();
export const getForgotPassword = createAction(GET_FORGOT_PASSWORD)<ForgotPasswordOptions>();
export const updateForgotPassword = createAction(UPDATE_FORGOT_PASSWORD)<ForgotPasswordResults>();
export const getFileURLReq = createAction(GET_FILE_URL)<FileURLOptions>();
export const updateFileURLReq = createAction(UPDATE_GET_FILE_URL)<FileURLResults>();
export const getCityListReq = createAction(GET_CITY_LIST)<ListCityOptions>();
export const updateCityListReq = createAction(UPDATE_CITY_LIST)<ListCityResults>();
export const clearCityReq = createAction(CLEAR_CITY_LIST)();
export const userProfilePutReq = createAction(USER_PROFILE_PUT_DATA)<UserProfilePutOptions>();
export const updateUserProfilePutReq = createAction(UPDATE_USER_PROFILE_PUT_DATA)<UserProfilePutResults>();
export const clearUserProfilePutReq = createAction(CLEAR_USER_PROFILE_PUT_DATA)();
export const passKeyReq = createAction(PASS_KEY_DATA)<PassKeyPutOptions>();
export const updatePassKeyReq = createAction(UPDATE_PASS_KEY_DATA)<PassKeyPutResults>();
export const clearPassKeyReq = createAction(CLEAR_PASS_KEY_DATA)();
export const uploadImageReq = createAction(UPLOAD_IMAGE_DATA)<UploadImageOptions>();
export const updateUploadImageReq = createAction(UPDATE_UPLOAD_IMAGE_DATA)<UploadImageResults>();
export const clearUploadImageReq = createAction(CLEAR_UPLOAD_IMAGE_DATA)();
export const getOrgListReq = createAction(GET_ORG_LIST)<ListOrgOptions>();
export const updateOrgListReq = createAction(UPDATE_GET_ORG_LIST)<ListOrgResults>();
export const getSkillListReq = createAction(GET_SKILL_LIST)<ListSkillOptions>();
export const updateSkillListReq = createAction(UPDATE_GET_SKILL_LIST)<ListSkillResults>();
export const getResumeProfile = createAction(GET_RESUME_PROFILE)<ResumeProfileOptions>();
export const updateResumeProfile = createAction(UPDATE_RESUME_PROFILE)<ResumeProfileResults>();
export const getResumeDataObj = createAction(GET_RESUME_OBJ)<ResumeObjOptions>();
export const updateResumeDataObj = createAction(UPDATE_RESUME_OBJ)<ResumeObjResults>();
export const getObjective = createAction(GET_OBJECTIVE)<ObjectiveOptions>();
export const updateObjective = createAction(UPDATE_OBJECTIVE)<ObjectiveResults>();
export const clearObjective = createAction(CLEAR_OBJECTIVE)();
export const getOrgProject = createAction(GET_ORG_PROJ)<OrgProjOptions>();
export const updateOrgProject = createAction(UPDATE_ORG_PROJ)<OrgProjResults>();
export const clearOrgProject = createAction(CLEAR_ORG_ORG_PROJ)();
export const getListOrgProj = createAction(GET_LIST_ORG_PROJ)<ListOrgProjOptions>();
export const updateListOrgProj = createAction(UPDATE_LIST_ORG_PROJ)<ListOrgProjResults>();
export const getAllJobs = createAction(GET_ALL_JOBS)<ListJobsOptions>();
export const updateAllJobs = createAction(UPDATE_ALL_JOBS)<ListJobsResults>();
export const getJobPositonById = createAction(GET_JOB_POSITION_BY_ID)<JobPositionByIdOptions>();
export const updateJobPositonById = createAction(UPDATE_JOB_POSITION_BY_ID)<JobPositionByIdResults>();
export const clearJobPositonById = createAction(CLEAR_JOB_POSITION_BY_ID)();
export const getAllPJobs = createAction(GET_ALL_P_JOBS)<ListPJobsOptions>();
export const getAPLPJobs = createAction(GET_APL_JOBS)<ListPJobsOptions>();
export const getSVDPJobs = createAction(GET_SVD_JOBS)<ListPJobsOptions>();
export const updateAllPJobs = createAction(UPDATE_ALL_P_JOBS)<ListPJobsResults>();
export const updateAPLPJobs = createAction(UPDATE_APL_JOBS)<ListPJobsResults>();
export const updateSVDPJobs = createAction(UPDATE_SVD_JOBS)<ListPJobsResults>();
export const getJobStatus = createAction(GET_JOB_STATUS)<JobStatusOptions>();
export const updateJobStatusReq = createAction(UPDATE_JOB_STATUS_DATA)<JobStatusResults>();
export const clearJobStatusReq = createAction(CLEAR_JOB_STATUS_DATA)();
export const delOrgProj = createAction(DELETE_ORG_PROJ)<DelOrgProjOptions>();
export const updateDelOrgProjReq = createAction(UPDATE_DELETE_ORG_PROJ)<DelOrgProjResults>();
export const getPreviewResume = createAction(GET_PREVIEW_RESUME)<ResumePreviewOptions>();
export const updatePreviewResume = createAction(UPDATE_PREVIEW_RESUME)<ResumePreviewResults>();
export const uploadVideoReq = createAction(UPLOAD_VIDEO_DATA)<UploadVideoOptions>();
export const updateUploadVideoReq = createAction(UPDATE_UPLOAD_VIDEO_DATA)<UploadVideoResults>();
export const clearUploadVideoReq = createAction(CLEAR_UPLOAD_VIDEO_DATA)();
export const getVideoURLReq = createAction(GET_VIDEO_URL)<VideoURLOptions>();
export const updateVideoURLReq = createAction(UPDATE_GET_VIDEO_URL)<VideoURLResults>();
export const getStrmListReq = createAction(GET_STRM_LIST)<ListStrmOptions>();
export const updateStrmListReq = createAction(UPDATE_STRM_LIST)<ListStrmResults>();
export const batchReq = createAction(BATCH_DATA)<BatchPutOptions>();
export const updateBatchReq = createAction(UPDATE_BATCH_DATA)<BatchPutResults>();
export const clearBatchReq = createAction(CLEAR_BATCH_DATA)();
export const getBatchListReq = createAction(GET_BATCH_LIST)<ListBatchOptions>();
export const updateBatchListReq = createAction(UPDATE_BATCH_LIST)<ListBatchResults>();
export const candReq = createAction(CAND_DATA)<CandPutOptions>();
export const updateCandReq = createAction(UPDATE_CAND_DATA)<CandPutResults>();
export const clearCandReq = createAction(CLEAR_CAND_DATA)();
export const getPCandListReq = createAction(GET_PCAND_LIST)<ListPCandOptions>();
export const updatePCandListReq = createAction(UPDATE_PCAND_LIST)<ListPCandResults>();
export const clearCandListReq = createAction(CLEAR_CAND_LIST_DATA)();
export const addAcadReq = createAction(ADD_ACAD_DATA)<AcadAddOptions>();
export const updateAddAcadReq = createAction(UPDATE_ADD_ACAD_DATA)<AcadAddResults>();
export const clearAddAcadReq = createAction(CLEAR_ADD_ACAD_DATA)();
export const getUnivListReq = createAction(GET_UNIV_LIST)<ListUnivOptions>();
export const updateUnivListReq = createAction(UPDATE_UNIV_LIST)<ListUnivResults>();
export const clearUnivListReq = createAction(CLEAR_UNIV_LIST_DATA)();
export const getMentorStatusListReq = createAction(GET_MNTR_STS_LIST)<ListMentorOptions>();
export const updateMentorStatusListReq = createAction(UPDATE_MNTR_STS_LIST)<ListMentorResults>();
export const clearMentorStatusListReq = createAction(CLEAR_MNTR_STS_LIST_DATA)();
export const getMentorAccess = createAction(GET_MENTOR_ACCESS)<MentorAccessUpdateOptions>();
export const updateMentorAccess = createAction(UPDATE_MENTOR_ACCESS)<MentorAccessUpdateResults>();
export const clearMentorAccess = createAction(CLEAR_MENTOR_ACCESS)();
