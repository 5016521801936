import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, InputGroup, InputGroupText, Label, Row,
} from 'reactstrap';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPerson, faPersonDress, faTransgender } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearAddAcadReq, registerCMPSReq,
} from '../../../../../store/campusXcel/actions';
import {
  UserType, APIHeader, ListLoginAccess,
} from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { RegisterCMPSUserInput } from '../../../../../services/register-campus-user-by-cx/register.types';
import { CollegeIdProps } from '../college.type';

export const AddCMPSUser: React.FC<CollegeIdProps> = ({ collegeId }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loaderUser, setLoaderUser] = useState(false);
  const [activeTab, setActiveTab] = React.useState('NA');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const userResponse = useSelector((state: RootState) => state.campusXcel.registerUser);
  const [user, setUser] = useState<RegisterCMPSUserInput>({
    fNme: '',
    lNme: '',
    uNme: '',
    dlCd: '+91',
    ph: '',
    phTp: '',
    gnd: '',
    eId: '',
    isAcs: false,
    isAgreeTrm: false,
    isAdm: false,
    lgnSts: '',
    acdId: '',
  });

  const loginStsOptions = ListLoginAccess.map((loginSts) => ({
    label: loginSts.label,
    value: loginSts.value,
  }));

  React.useEffect(() => {
    setUser({ ...user, acdId: collegeId });
  }, [collegeId]);

  const handleSelectLoginStsData = (e: any) => {
    if (e) {
      setUser({ ...user, lgnSts: e.value });
    } else {
      setUser({ ...user, lgnSts: '' });
    }
  };

  const handleChangeUser = (event: any) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleCheckChange = (e: any) => {
    if (e.target.checked === true) {
      setUser({ ...user, phTp: 'SMRTPHN' });
    } else {
      setUser({ ...user, phTp: 'SMSPHN' });
    }
  };

  const handleCheckAccess = (event: any) => {
    setUser({ ...user, [event.target.name]: event.target.checked });
  };

  const handleGenderRegister = (gender_data: string) => {
    if (gender_data === 'M') {
      setUser({ ...user, gnd: 'M' });
      setActiveTab('M');
    } else if (gender_data === 'F') {
      setUser({ ...user, gnd: 'F' });
      setActiveTab('F');
    } else if (gender_data === 'T') {
      setUser({ ...user, gnd: 'T' });
      setActiveTab('T');
    }
  };

  const handleSubmitUser = (e: any) => {
    e.preventDefault();
    setLoaderUser(true);
    dispatch(registerCMPSReq({
      inputBody: user,
      requestType: APIHeader.REQ_ADD_CMPS_USR,
      userType: UserType.CX,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (userResponse.error && userResponse.message !== '') {
      setLoaderUser(false);
      setAlertMsg(userResponse.message);
      setErrorShowAlert(true);
    }
  }, [userResponse.error, userResponse.message]);

  console.log('check user res', userResponse);
  React.useEffect(() => {
    if (!userResponse.error && userResponse.data !== '') {
      setLoaderUser(false);
      Promise.resolve(dispatch(clearAddAcadReq()))
        .then(() => {
          setUser({
            fNme: '',
            lNme: '',
            uNme: '',
            dlCd: '+91',
            ph: '',
            phTp: '',
            gnd: '',
            eId: '',
            isAcs: false,
            isAgreeTrm: false,
            isAdm: false,
            lgnSts: '',
            acdId: collegeId,
          });
          setAlertMsg(userResponse.data);
          setSuccessShowAlert(true);
        });
    }
  }, [userResponse.error, userResponse.data]);

  return (
    <Row className="pt-2">
      { loaderUser ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 11, order: 1 }} md={{ size: 8, order: 1 }} xs={{ order: 1 }}>
        <Form onSubmit={handleSubmitUser}>
          <Card className="form-card">
            <CardHeader className="form-card-header">
              Onboard New Campus User
            </CardHeader>
            <CardBody>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <h6 className="input-header-text">First Name<span className="mandate-star">*</span></h6>
                <Input
                  placeholder="Enter First Name"
                  type="text"
                  required
                  name="fNme"
                  value={user.fNme}
                  className="campus-input"
                  onChange={handleChangeUser}
                />
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <h6 className="input-header-text">Last Name<span className="mandate-star">*</span></h6>
                <Input
                  placeholder="Enter Last Name"
                  type="text"
                  required
                  name="lNme"
                  value={user.lNme}
                  className="campus-input"
                  onChange={handleChangeUser}
                />
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <span className="input-header-text">Phone<span className="mandate-star">*</span></span>
                <InputGroup>
                  <InputGroupText className="campus-input">
                    +91
                  </InputGroupText>
                  <Input
                    placeholder="Enter your Phone Number"
                    type="number"
                    required
                    name="ph"
                    value={user.ph}
                    onChange={handleChangeUser}
                    className="campus-input mx-1"
                  />
                </InputGroup>
                <h6 className="text-lite-grey mx-1 pt-1">WhatsApp services please provide same number</h6>
              </div>
              <div className="text-right-login-campus-register mx-3 pt-3">
                <span className="text-whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="campus-icon-green mx-1" /> WhatsApp</span>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={handleCheckChange}
                    className="campus-check-box ml-1 cursor-pointer"
                  />
                </Label>
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <h6 className="input-header-text">Email Id<span className="mandate-star">*</span></h6>
                <Input
                  placeholder="Enter your Email Id"
                  type="text"
                  required
                  name="eId"
                  value={user.eId}
                  onChange={handleChangeUser}
                  className="campus-input"
                />
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <span className="input-header-text">Gender<span className="mandate-star">*</span></span>
                <Row className="align-center1">
                  <Col lg="4" md="4" xs="4" className="pt-2">
                    <Card
                      onClick={() => handleGenderRegister('M')}
                      className={activeTab === 'NA' ? 'campus-radio-box' : activeTab === 'M' ? 'campus-radio-box-active' : 'campus-radio-box'}
                    >
                      <span className="campus-gender-text">
                        <FontAwesomeIcon className="campus-font-icon mx-2" icon={faPerson} />
                        <span className="display-data-none">Male</span>
                      </span>
                    </Card>
                  </Col>
                  <Col lg="4" md="4" xs="4" className="pt-2">
                    <Card
                      onClick={() => handleGenderRegister('F')}
                      className={activeTab === 'NA' ? 'campus-radio-box' : activeTab === 'F' ? 'campus-radio-box-active' : 'campus-radio-box'}
                    >
                      <span className="campus-gender-text">
                        <FontAwesomeIcon className="campus-font-icon mx-2" icon={faPersonDress} />
                        <span className="display-data-none">Female</span>
                      </span>
                    </Card>
                  </Col>
                  <Col lg="4" md="4" xs="4" className="pt-2">
                    <Card
                      onClick={() => handleGenderRegister('T')}
                      className={activeTab === 'NA' ? 'campus-radio-box' : activeTab === 'T' ? 'campus-radio-box-active' : 'campus-radio-box'}
                    >
                      <span className="campus-gender-text">
                        <FontAwesomeIcon className="campus-font-icon mx-2" icon={faTransgender} />
                        <span className="display-data-none">Transgender</span>
                      </span>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <h6 className="input-header-text">Access<span className="mandate-star">*</span></h6>
                <Row>
                  <Col lg="4" md="4" xs="6" className="pt-2">
                    <div className="pt-3 align-start">
                      <Label check className="align-center">
                        <Input
                          type="checkbox"
                          name="isAcs"
                          onChange={handleCheckAccess}
                          className="campus-check-box ml-1 pt-1 cursor-pointer"
                        />
                        <div className="pt-1"><span className="collapse-header">Is Access</span></div>
                      </Label>
                    </div>
                  </Col>
                  <Col lg="4" md="4" xs="6" className="pt-2">
                    <div className="pt-3 align-start">
                      <Label check className="align-center">
                        <Input
                          type="checkbox"
                          name="isAdm"
                          onChange={handleCheckAccess}
                          className="campus-check-box ml-1 pt-1 cursor-pointer"
                        />
                        <div className="pt-1"><span className="collapse-header">Is Admin</span></div>
                      </Label>
                    </div>
                  </Col>
                  <Col lg="4" md="4" xs="6" className="pt-2">
                    <div className="pt-3 align-start">
                      <Label check className="align-center">
                        <Input
                          type="checkbox"
                          name="isAgreeTrm"
                          onChange={handleCheckAccess}
                          className="campus-check-box ml-1 pt-1 cursor-pointer"
                        />
                        <div className="pt-1"><span className="collapse-header">Terms</span></div>
                      </Label>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="text-right-login-campus-register mx-3 pt-4">
                <h6 className="input-header-text">Login Status<span className="mandate-star">*</span></h6>
                <Select
                  options={loginStsOptions}
                  onChange={handleSelectLoginStsData}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      position: 'relative',
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#F6B26B' },
                      border: '1px solid #DDE0E4',
                      minHeight: '50px',
                      borderRadius: '5px',
                      padding: 1,
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 15,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                  noOptionsMessage={() => 'No Login Status Found'}
                  placeholder="Enter Login Status"
                />
              </div>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end mb-2">
                <Button className="campus-button-oval mx-1">
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default AddCMPSUser;
