import { putJSON } from '../../../../utils';
import { getConfig } from '../../../../utils/config/config';
import { OrgProjOptions, OrgProjResponse } from './org-proj.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();
export const CampusOrgProjPutRequest = ({
  inputBody, token, userType, requestType,
}: OrgProjOptions): Promise<OrgProjResponse> => {
  if (token) {
    return putJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return putJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, '', requestType, inputBody as unknown as BodyInit);
};
