import React from 'react';
import {
  faBars, faBuildingUser, faClose, faLaptopFile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import { Button } from 'reactstrap';

export const Navbar: React.FC = () => {
  const history = useNavigate();
  const [click, setClick] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };
  const registerPage = () => {
    history('/register');
    window.scrollTo(0, 0);
  };
  const loginPage = () => {
    history('/login');
    window.scrollTo(0, 0);
  };
  return (
    <div className="navbar">
      <NavLink to="/home" className="nav-logo">
        <img style={{ height: '45px', width: 'auto' }} src="/campusXcel.png" alt="campusXcelLogo" />
      </NavLink>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-item">
          <NavLink
            className="nav-links"
            to="/#about"
            title="About"
            onClick={() => history('#about')}
          >
            <FontAwesomeIcon icon={faBuildingUser} className="nav-icon-link" /><span className="nav-text-header">About</span>
          </NavLink>
        </li>
        <li className="nav-item mx-5">
          <NavLink
            className="nav-links"
            to="/contact-us"
            title="Have Queries ? Connect with us"
            onClick={() => { window.scrollTo(0, 0); }}
          >
            <FontAwesomeIcon icon={faLaptopFile} className="nav-icon-link" /><span className="nav-text-header">Contact Us</span>
          </NavLink>
        </li>
        <li className="nav-item-login">
          <Button
            className="campus-button-oval mx-1"
            to="/#"
            type="button"
            title="Register to CampusXcel Portal"
            onClick={registerPage}
          >
            Register
          </Button>
        </li>
        <li className="nav-item-login">
          <Button
            className="campus-button-oval mx-1"
            to="/#"
            type="button"
            title="Login to CampusXcel Portal"
            onClick={loginPage}
          >
            Login
          </Button>
        </li>
      </ul>
      <NavLink
        className="nav-icon"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
    </div>
  );
};

export default Navbar;
