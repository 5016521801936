import { ResumePreviewResponse, ResumePreviewResults } from './get-preview.service.types';

export const ResumePreviewTransform: (response: ResumePreviewResponse) => ResumePreviewResults = (resumeObjResults) => {
  const { data, is_error, message } = resumeObjResults;
  const result :ResumePreviewResults = {
    allData: {
      currLoc: '',
      cEId: '',
      btCd: '',
      addr: '',
      diffAbl: false,
      cnty: '',
      langKnwn: [],
      updtOn: '',
      orgProj: [],
      CandCd: '',
      diffAblRsn: '',
      isPrefPAN: false,
      obj: '',
      CandId: '',
      dOB: '',
      dlCd: '',
      cPh: '',
      cPhTp: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
      edu: {
        acad: '', strm: '', crsTp: '', sDte: '', eDte: '',
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.allData = data;
  }
  return result;
};
