import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearAddAcadReq,
  clearBatchReq,
  clearCandListReq,
  clearCandReq,
  clearCityReq,
  clearContactUsPostReq, clearJobPositonById, clearJobStatusReq, clearMentorAccess, clearMentorStatusListReq, clearObjective, clearOrgProject, clearPassKeyReq, clearRegisterCMPSPostReq,
  clearUnivListReq,
  clearUploadImageReq, clearUploadVideoReq, clearUserProfilePutReq, updateAcadListReq, updateAddAcadReq, updateAllJobs, updateAllPJobs, updateAPLPJobs, updateBatchListReq, updateBatchReq, updateCandReq, updateCityListReq,
  updateContactUsPostReq, updateData, updateDelOrgProjReq, updateFileURLReq, updateForgotPassword,
  updateJobPositonById,
  updateJobStatusReq,
  updateListOrgProj,
  updateMentorAccess,
  updateMentorStatusListReq,
  updateObjective, updateOrgListReq, updateOrgProject, updatePassKeyReq, updatePCandListReq, updatePreviewResume, updateProfile,
  updateRegisterCMPSPostReq, updateResumeDataObj, updateResumeProfile,
  updateSkillListReq, updateStrmListReq, updateSVDPJobs, updateToken, updateUnivListReq, updateUploadImageReq, updateUploadVideoReq, updateUserProfilePutReq, updateVideoURLReq,
} from './actions';
import * as actions from './actions';
import { CampusXcelAppState } from './action.types';

export const initialState: CampusXcelAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  registerUser: {
    message: '',
    data: '',
    error: false,
  },
  getAcadMetaList: {
    tCnt: 0,
    fCnt: 0,
    acadData: [],
    isError: false,
    message: '',
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  profileData: {
    isError: false,
    message: '',
    profileData: {
      fNme: '',
      uNme: '',
      lNme: '',
      ph: '',
      eId: '',
      avtr: '',
      gnd: '',
      dlCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      isAdm: false,
    },
  },
  forgotPassKeyData: {
    forgotPasswordData: '',
    message: '',
    isError: false,
  },
  updateFileURL: {
    url: '',
    isError: false,
    message: '',
  },
  getCityMetaList: {
    data: [],
    message: '',
    isError: false,
  },
  resumeProfileData: {
    perData: {
      currLoc: '',
      cEId: '',
      diffAbl: false,
      cnty: '',
      diffAblRsn: '',
      addr: '',
      langKnwn: [],
      isPrefPAN: false,
      dOB: '',
      dlCd: '',
      cPh: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
    },
    isError: false,
    message: '',
  },
  updateUserProfile: {
    message: '',
    errorMessage: '',
    error: false,
  },
  updatePassKey: {
    message: '',
    error: false,
  },
  getOrgMetaList: {
    tCnt: 0,
    fCnt: 0,
    orgData: [],
    orgInfo: [],
    isError: false,
    message: '',
  },
  getSkillMetaList: {
    tCnt: 0,
    fCnt: 0,
    skillData: [],
    skillInfo: [],
    isError: false,
  },
  updateProfileImage: {
    message: '',
    error: false,
  },
  getObjectiveResume: {
    obj: '',
    message: '',
    isError: false,
  },
  updateObjectiveResume: {
    message: '',
    error: false,
  },
  updateOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  listOrgProj: {
    orgProj: [],
    isError: false,
    message: '',
  },
  listJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listALLJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listAPLJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listSVDJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  jobPositionById: {
    isError: false,
    message: '',
    jobData: {
      JobId: '',
      sDte: '',
      eduTp: [],
      isAbld: false,
      isPub: false,
      mjrSkls: [{
        nme: '',
        yr: 0,
      }],
      JobCd: '',
      pref: '',
      nme: '',
      logoUrl: '',
      jbTp: [],
      isFml: false,
      eDte: '',
      minSal: '',
      minExp: 0,
      maxExp: 0,
      maxSal: '',
      dep: '',
      hrTp: '',
      isEdu: false,
      useLogo: false,
      isHirClg: false,
      loc: [],
      orgNme: {
        nme: '',
        loc: [],
        web: '',
        abt: '',
      },
      rr: [],
      abtProj: [],
      mnrSkls: [],
    },
  },
  updateJobStatus: {
    message: '',
    error: false,
    errorMessage: '',
  },
  deleteOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  previewResume: {
    allData: {
      currLoc: '',
      cEId: '',
      btCd: '',
      addr: '',
      diffAbl: false,
      cnty: '',
      langKnwn: [],
      updtOn: '',
      orgProj: [],
      CandCd: '',
      diffAblRsn: '',
      isPrefPAN: false,
      obj: '',
      CandId: '',
      dOB: '',
      dlCd: '',
      cPh: '',
      cPhTp: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
      edu: {
        acad: '', strm: '', crsTp: '', sDte: '', eDte: '',
      },
    },
    isError: false,
    message: '',
  },
  updateProfileVideo: {
    message: '',
    error: false,
  },
  updateVideoURL: {
    url: '',
    isError: false,
    message: '',
  },
  getStrmMetaList: {
    tCnt: 0,
    fCnt: 0,
    strmData: [],
    strmInfo: [],
    isError: false,
  },
  updateBatch: {
    message: '',
    error: false,
  },
  getBatchList: {
    tCnt: 0,
    fCnt: 0,
    acadData: [],
    isError: false,
    message: '',
  },
  updateCand: {
    message: '',
    error: false,
    candData: '',
  },
  getPCandList: {
    tCnt: 0,
    fCnt: 0,
    cndData: [],
    isError: false,
    message: '',
  },
  getUnivMetaList: {
    tCnt: 0,
    fCnt: 0,
    univData: [],
    isError: false,
    message: '',
  },
  addAcad: {
    message: '',
    error: false,
    data: '',
  },
  getMentorList: {
    tCnt: 0,
    fCnt: 0,
    mntrData: [],
    isError: false,
    message: '',
  },
  updateMentorAccess: {
    message: '',
    error: false,
    data: '',
  },
};
export type AllAction = ActionType<typeof actions>;
const campusXcelReducer: Reducer<CampusXcelAppState, AllAction> = (state: CampusXcelAppState = initialState, action: AllAction): CampusXcelAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateAcadListReq):
      return {
        ...state,
        getAcadMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          acadData: action.payload.acadData,
          message: action.payload.message,
        },
      };
    case getType(updateRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: initialState.registerUser,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateProfile):
      return {
        ...state,
        profileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: {
            fNme: action.payload.profileData.fNme,
            uNme: action.payload.profileData.uNme,
            lNme: action.payload.profileData.lNme,
            ph: action.payload.profileData.ph,
            eId: action.payload.profileData.eId,
            UserTp: action.payload.profileData.UserTp,
            avtr: action.payload.profileData.avtr,
            gnd: action.payload.profileData.gnd,
            dlCd: action.payload.profileData.dlCd,
            isPKReset: action.payload.profileData.isPKReset,
            lgnSts: action.payload.profileData.lgnSts,
            isAdm: action.payload.profileData.isAdm,
          },
        },
      };
    case getType(updateForgotPassword):
      return {
        ...state,
        forgotPassKeyData: {
          forgotPasswordData: action.payload.forgotPasswordData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(updateFileURLReq):
      return {
        ...state,
        updateFileURL: {
          url: action.payload.url,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateCityListReq):
      return {
        ...state,
        getCityMetaList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearCityReq):
      return {
        ...state,
        getCityMetaList: initialState.getCityMetaList,
      };
    case getType(updateResumeProfile):
      return {
        ...state,
        resumeProfileData: {
          perData: action.payload.perData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: initialState.updateUserProfile,
      };
    case getType(updatePassKeyReq):
      return {
        ...state,
        updatePassKey: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPassKeyReq):
      return {
        ...state,
        updateFileURL: initialState.updateFileURL,
      };
    case getType(updateOrgListReq):
      return {
        ...state,
        getOrgMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          orgData: action.payload.orgData,
          orgInfo: action.payload.orgInfo,
          message: action.payload.message,
        },
      };
    case getType(updateSkillListReq):
      return {
        ...state,
        getSkillMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          skillData: action.payload.skillData,
          skillInfo: action.payload.skillInfo,
        },
      };
    case getType(updateUploadImageReq):
      return {
        ...state,
        updateProfileImage: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearUploadImageReq):
      return {
        ...state,
        updateProfileImage: initialState.updateProfileImage,
      };
    case getType(updateUploadVideoReq):
      return {
        ...state,
        updateProfileVideo: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearUploadVideoReq):
      return {
        ...state,
        updateProfileVideo: initialState.updateProfileVideo,
      };
    case getType(updateObjective):
      return {
        ...state,
        updateObjectiveResume: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearObjective):
      return {
        ...state,
        updateObjectiveResume: initialState.updateObjectiveResume,
      };
    case getType(updateResumeDataObj):
      return {
        ...state,
        getObjectiveResume: {
          obj: action.payload.obj,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateAllJobs):
      return {
        ...state,
        listJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'executed',
        },
      };
    case getType(updateAllPJobs):
      return {
        ...state,
        listALLJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal all job executed',
        },
      };
    case getType(updateAPLPJobs):
      return {
        ...state,
        listAPLJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal apl job executed',
        },
      };
    case getType(updateSVDPJobs):
      return {
        ...state,
        listSVDJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal svd job executed',
        },
      };
    case getType(updateJobPositonById):
      return {
        ...state,
        jobPositionById: {
          jobData: action.payload.jobData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearJobPositonById):
      return {
        ...state,
        jobPositionById: {
          jobData: initialState.jobPositionById.jobData,
          isError: initialState.jobPositionById.isError,
          message: initialState.jobPositionById.message,
        },
      };
    case getType(updateOrgProject):
      return {
        ...state,
        updateOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearOrgProject):
      return {
        ...state,
        updateOrgProj: initialState.updateOrgProj,
      };
    case getType(updateJobStatusReq):
      return {
        ...state,
        updateJobStatus: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearJobStatusReq):
      return {
        ...state,
        updateJobStatus: initialState.updateJobStatus,
      };
    case getType(updateListOrgProj):
      return {
        ...state,
        listOrgProj: {
          isError: action.payload.isError,
          message: action.payload.message,
          orgProj: action.payload.orgProj,
        },
      };
    case getType(updateDelOrgProjReq):
      return {
        ...state,
        deleteOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(updatePreviewResume):
      return {
        ...state,
        previewResume: {
          allData: action.payload.allData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateVideoURLReq):
      return {
        ...state,
        updateVideoURL: {
          url: action.payload.url,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateStrmListReq):
      return {
        ...state,
        getStrmMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          strmData: action.payload.strmData,
          strmInfo: action.payload.strmInfo,
        },
      };
    case getType(updateBatchReq):
      return {
        ...state,
        updateBatch: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearBatchReq):
      return {
        ...state,
        updateBatch: initialState.updateBatch,
      };
    case getType(updateBatchListReq):
      return {
        ...state,
        getBatchList: {
          isError: action.payload.isError,
          message: action.payload.message,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          acadData: action.payload.acadData,
        },
      };
    case getType(updateCandReq):
      return {
        ...state,
        updateCand: {
          message: action.payload.message,
          error: action.payload.error,
          candData: action.payload.candData,
        },
      };
    case getType(clearCandReq):
      return {
        ...state,
        updateCand: initialState.updateCand,
      };
    case getType(updatePCandListReq):
      return {
        ...state,
        getPCandList: {
          isError: action.payload.isError,
          message: action.payload.message,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          cndData: action.payload.cndData,
        },
      };
    case getType(clearCandListReq):
      return {
        ...state,
        getPCandList: {
          isError: false,
          message: '',
          tCnt: 0,
          fCnt: 0,
          cndData: [],
        },
      };
    case getType(updateAddAcadReq):
      return {
        ...state,
        addAcad: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddAcadReq):
      return {
        ...state,
        updateCand: initialState.updateCand,
      };
    case getType(updateUnivListReq):
      return {
        ...state,
        getUnivMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          univData: action.payload.univData,
          message: action.payload.message,
        },
      };
    case getType(clearUnivListReq):
      return {
        ...state,
        getUnivMetaList: {
          isError: false,
          tCnt: 0,
          fCnt: 0,
          univData: [],
          message: '',
        },
      };
    case getType(updateMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          mntrData: action.payload.mntrData,
          message: action.payload.message,
        },
      };
    case getType(clearMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: false,
          tCnt: 0,
          fCnt: 0,
          mntrData: [],
          message: '',
        },
      };
    case getType(updateMentorAccess):
      return {
        ...state,
        updateMentorAccess: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearMentorAccess):
      return {
        ...state,
        updateMentorAccess: initialState.updateMentorAccess,
      };
    default:
      return state;
  }
};
export default campusXcelReducer;
