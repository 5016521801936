export const API_ERROR = 'API_ERROR';
export const SHOW_DATA = 'SHOW_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONTACT_US_DATA = 'CONTACT_US_DATA';
export const UPDATE_CONTACT_US_DATA = 'UPDATE_CONTACT_US_DATA';
export const CLEAR_CONTACT_US_DATA = 'CLEAR_CONTACT_US_DATA';
export const GET_ACAD_LIST = 'GET_ACAD_LIST';
export const UPDATE_GET_ACAD_LIST = 'UPDATE_GET_ACAD_LIST';
export const REGISTER_CMPS_DATA = 'REGISTER_CMPS_DATA';
export const UPDATE_REGISTER_CMPS_DATA = 'UPDATE_REGISTER_CMPS_DATA';
export const CLEAR_REGISTER_CMPS_DATA = 'CLEAR_REGISTER_CMPS_DATA';
export const GET_LOGIN_TOKEN = 'GET_LOGIN_TOKEN';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD';
export const UPDATE_FORGOT_PASSWORD = 'UPDATE_FORGOT_PASSWORD';
export const GET_FILE_URL = 'GET_FILE_URL';
export const UPDATE_GET_FILE_URL = 'UPDATE_GET_FILE_URL';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const UPDATE_CITY_LIST = 'UPDATE_CITY_LIST';
export const CLEAR_CITY_LIST = 'CLEAR_CITY_LIST';
export const USER_PROFILE_PUT_DATA = 'USER_PROFILE_PUT_DATA';
export const UPDATE_USER_PROFILE_PUT_DATA = 'UPDATE_USER_PROFILE_PUT_DATA';
export const CLEAR_USER_PROFILE_PUT_DATA = 'CLEAR_USER_PROFILE_PUT_DATA';
export const PASS_KEY_DATA = 'PASS_KEY_DATA';
export const UPDATE_PASS_KEY_DATA = 'UPDATE_PASS_KEY_DATA';
export const CLEAR_PASS_KEY_DATA = 'CLEAR_PASS_KEY_DATA';
export const UPLOAD_IMAGE_DATA = 'UPLOAD_IMAGE_DATA';
export const UPDATE_UPLOAD_IMAGE_DATA = 'UPDATE_UPLOAD_IMAGE_DATA';
export const CLEAR_UPLOAD_IMAGE_DATA = 'CLEAR_UPLOAD_IMAGE_DATA';
export const GET_ORG_LIST = 'GET_ORG_LIST';
export const UPDATE_GET_ORG_LIST = 'UPDATE_GET_ORG_LIST';
export const GET_SKILL_LIST = 'GET_SKILL_LIST';
export const UPDATE_GET_SKILL_LIST = 'UPDATE_GET_SKILL_LIST';
export const GET_RESUME_PROFILE = 'GET_RESUME_PROFILE';
export const UPDATE_RESUME_PROFILE = 'UPDATE_RESUME_PROFILE';
export const GET_RESUME_OBJ = 'GET_RESUME_OBJ';
export const UPDATE_RESUME_OBJ = 'UPDATE_RESUME_OBJ';
export const GET_OBJECTIVE = 'GET_OBJECTIVE';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const CLEAR_OBJECTIVE = 'CLEAR_OBJECTIVE';
export const GET_ORG_PROJ = 'GET_ORG_PROJ';
export const UPDATE_ORG_PROJ = 'UPDATE_ORG_PROJ';
export const CLEAR_ORG_ORG_PROJ = 'CLEAR_ORG_ORG_PROJ';
export const GET_LIST_ORG_PROJ = 'GET_LIST_ORG_PROJ';
export const UPDATE_LIST_ORG_PROJ = 'UPDATE_LIST_ORG_PROJ';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const UPDATE_ALL_JOBS = 'UPDATE_ALL_JOBS';
export const GET_JOB_POSITION_BY_ID = 'GET_JOB_POSITION_BY_ID';
export const UPDATE_JOB_POSITION_BY_ID = 'UPDATE_JOB_POSITION_BY_ID';
export const CLEAR_JOB_POSITION_BY_ID = 'CLEAR_JOB_POSITION_BY_ID';
export const GET_ALL_P_JOBS = 'GET_ALL_P_JOBS';
export const GET_APL_JOBS = 'GET_APL_JOBS';
export const GET_SVD_JOBS = 'GET_SVD_JOBS';
export const UPDATE_ALL_P_JOBS = 'UPDATE_ALL_P_JOBS';
export const UPDATE_APL_JOBS = 'UPDATE_APL_JOBS';
export const UPDATE_SVD_JOBS = 'UPDATE_SVD_JOBS';
export const GET_JOB_STATUS = 'GET_JOB_STATUS';
export const UPDATE_JOB_STATUS_DATA = 'UPDATE_JOB_STATUS_DATA';
export const CLEAR_JOB_STATUS_DATA = 'CLEAR_JOB_STATUS_DATA';
export const DELETE_ORG_PROJ = 'DELETE_ORG_PROJ';
export const UPDATE_DELETE_ORG_PROJ = 'UPDATE_DELETE_ORG_PROJ';
export const GET_PREVIEW_RESUME = 'GET_PREVIEW_RESUME';
export const UPDATE_PREVIEW_RESUME = 'UPDATE_PREVIEW_RESUME';
export const UPLOAD_VIDEO_DATA = 'UPLOAD_VIDEO_DATA';
export const UPDATE_UPLOAD_VIDEO_DATA = 'UPDATE_UPLOAD_VIDEO_DATA';
export const CLEAR_UPLOAD_VIDEO_DATA = 'CLEAR_UPLOAD_VIDEO_DATA';
export const GET_VIDEO_URL = 'GET_VIDEO_URL';
export const UPDATE_GET_VIDEO_URL = 'UPDATE_GET_VIDEO_URL';
export const GET_STRM_LIST = 'GET_STRM_LIST';
export const UPDATE_STRM_LIST = 'UPDATE_STRM_LIST';
export const BATCH_DATA = 'BATCH_DATA';
export const UPDATE_BATCH_DATA = 'UPDATE_BATCH_DATA';
export const CLEAR_BATCH_DATA = 'CLEAR_BATCH_DATA';
export const GET_BATCH_LIST = 'GET_BATCH_LIST';
export const UPDATE_BATCH_LIST = 'UPDATE_BATCH_LIST';
export const CAND_DATA = 'CAND_DATA';
export const UPDATE_CAND_DATA = 'UPDATE_CAND_DATA';
export const CLEAR_CAND_DATA = 'CLEAR_CAND_DATA';
export const GET_PCAND_LIST = 'GET_PCAND_LIST';
export const UPDATE_PCAND_LIST = 'UPDATE_PCAND_LIST';
export const CLEAR_CAND_LIST_DATA = 'CLEAR_CAND_LIST_DATA';
export const ADD_ACAD_DATA = 'ADD_ACAD_DATA';
export const UPDATE_ADD_ACAD_DATA = 'UPDATE_ADD_ACAD_DATA';
export const CLEAR_ADD_ACAD_DATA = 'CLEAR_ADD_ACAD_DATA';
export const GET_UNIV_LIST = 'GET_UNIV_LIST';
export const UPDATE_UNIV_LIST = 'UPDATE_UNIV_LIST';
export const CLEAR_UNIV_LIST_DATA = 'CLEAR_UNIV_LIST_DATA';
export const GET_MNTR_STS_LIST = 'GET_MNTR_STS_LIST';
export const UPDATE_MNTR_STS_LIST = 'UPDATE_MNTR_STS_LIST';
export const CLEAR_MNTR_STS_LIST_DATA = 'CLEAR_MNTR_STS_LIST_DATA';
export const GET_MENTOR_ACCESS = 'GET_MENTOR_ACCESS';
export const UPDATE_MENTOR_ACCESS = 'UPDATE_MENTOR_ACCESS';
export const CLEAR_MENTOR_ACCESS = 'CLEAR_MENTOR_ACCESS';
