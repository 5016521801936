import { ListUnivResponse, ListUnivResults } from './list-univ.service.types';

export const ListUnivTransform: (response: ListUnivResponse) => ListUnivResults = (listUnivResults) => {
  const { data, is_error, message } = listUnivResults;
  const result: ListUnivResults = {
    tCnt: 0,
    fCnt: 0,
    univData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.univData = data.univData;
    result.message = 'executed';
  }
  return result;
};
