import { UploadVideoResponse, UploadVideoResults } from './upload-video.service.types';

export const UploadVideoTransform: (response: UploadVideoResponse) => UploadVideoResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :UploadVideoResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
    // result.errorMessage = 'Backend Issue';
  } else {
    result.message = data.message;
  }
  return result;
};
