import {
  Col, Row,
} from 'reactstrap';
import './contact.scss';

export const Contactus: React.FC = () => (
  <div className="contact-campusXcel-bg align-center">
    <Row className="mx-3">
      <Col lg={{ size: 7, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
        <div className="ml-2 pt-5">
          <span className="display-6 text-intro-sub-header">
            Connect With Us
          </span>
          <div className="pt-5 align-text-justify">
            <span className="text-why pt-5">
              If you are interested in collaborating or working together as an organization, let&apos;s get in touch and connect
              <br />
              <br />
              Email us : <a href="mailto:support@wdsinfotech.com" className="text-email">support@wdsinfotech.com</a>
            </span>
          </div>
        </div>
      </Col>
      <Col lg={{ size: 5, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center">
          <img
            alt="..."
            height="400px"
            src="images/home/contact-us.png"
          />
        </div>
      </Col>
    </Row>
  </div>
);

export default Contactus;
