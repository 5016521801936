import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Row,
} from 'reactstrap';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addAcadReq, clearAddAcadReq, clearCityReq, clearUnivListReq, getCityListReq, getUnivListReq,
} from '../../../../../store/campusXcel/actions';
import {
  UserType, APIHeader, ListCityTier, ApiLIMIT,
} from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { AcadAddArrayDataInput } from '../../../../../services/meta-data/add-academy/add-new-acad.types';
import { UnivResult } from '../../../../../services/meta-data/list-university/list-univ.types';
import { CityResult } from '../../../../../services/meta-data/list-city-loc/list-city.types';

export const AddColleges: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loaderAcad, setLoaderAcad] = useState(false);
  const [loaderUniv, setLoaderUniv] = useState(false);
  const [loaderCity, setLoaderCity] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [univListData, setUnivListData] = useState<UnivResult[]>([]);
  const [cityListData, setCityListData] = useState<CityResult[]>([]);
  const univListResponse = useSelector((state: RootState) => state.campusXcel.getUnivMetaList);
  const collegeResponse = useSelector((state: RootState) => state.campusXcel.addAcad);
  const cityListResponse = useSelector((state: RootState) => state.campusXcel.getCityMetaList);
  const [college, setCollege] = useState<AcadAddArrayDataInput>({
    acad: [{
      nme: '',
      univ: '',
      grp: '',
      addr: '',
      tr: 0,
      logo: '',
      city: '',
      cntPh: '',
      cntEId: '',
    }],
  });

  const tierOptions = ListCityTier.map((tier) => ({
    label: tier.label,
    value: tier.value,
  }));

  const handleSelectTierData = (e: any, tierIndex: number) => {
    if (e) {
      const updatedTier = [...college.acad];
      updatedTier[tierIndex].tr = e.value;
      setCollege({ ...college, acad: updatedTier });
    } else {
      const updatedTier = [...college.acad];
      updatedTier[tierIndex].tr = 0;
      setCollege({ ...college, acad: updatedTier });
    }
  };

  React.useEffect(() => {
    dispatch(getUnivListReq({
      requestType: APIHeader.REQ_AGET_ALL_UNIV,
      userType: UserType.CX,
      token: tokenData,
    }));
  }, []);

  React.useEffect(() => {
    if (univListResponse.isError && univListResponse.message !== '') {
      setLoaderUniv(false);
      setAlertMsg(univListResponse.message);
      setErrorShowAlert(true);
    }
  }, [univListResponse.isError, univListResponse.message]);

  React.useEffect(() => {
    if (!univListResponse.isError && univListResponse.message !== '') {
      setLoaderUniv(false);
      Promise.resolve(setUnivListData(univListResponse.univData))
        .then(() => {
          dispatch(clearUnivListReq());
        });
    }
  }, [univListResponse.isError, univListResponse.message]);

  const handleChangeAcad = (index: number, event: any) => {
    const updatedAcad = [...college.acad];
    updatedAcad[index] = { ...updatedAcad[index], [event.target.name]: event.target.value };
    setCollege({ ...college, acad: updatedAcad });
  };

  const univNameoptions = univListData.map((univ) => ({
    label: univ.nme,
    value: univ.MetaId,
  }));

  const handleSelectUnivData = (e: any, univIndex: number) => {
    if (e) {
      const updatedUniv = [...college.acad];
      updatedUniv[univIndex].univ = e.value;
      setCollege({ ...college, acad: updatedUniv });
    } else {
      const updatedUniv = [...college.acad];
      updatedUniv[univIndex].univ = '';
      setCollege({ ...college, acad: updatedUniv });
    }
  };

  React.useEffect(() => {
    dispatch(getCityListReq({
      requestType: APIHeader.REQ_GET_ALL_CITY_META,
      userType: UserType.CX,
      token: tokenData,
      limit: ApiLIMIT.LIMIT,
      searchData: '',
    }));
  }, []);

  React.useEffect(() => {
    if (cityListResponse.isError && cityListResponse.message !== '') {
      setLoaderCity(false);
      setAlertMsg(cityListResponse.message);
      setErrorShowAlert(true);
    }
  }, [cityListResponse.isError, cityListResponse.message]);

  React.useEffect(() => {
    if (!cityListResponse.isError && cityListResponse.message !== '') {
      setLoaderCity(false);
      Promise.resolve(setCityListData(cityListResponse.data))
        .then(() => {
          dispatch(clearCityReq());
        });
    }
  }, [cityListResponse.isError, cityListResponse.message]);

  const cityNameoptions = cityListData.map((city) => ({
    label: city.cty,
    value: city.cty,
  }));

  const handleSelectCityData = (e: any, cityIndex: number) => {
    if (e) {
      const updatedCity = [...college.acad];
      updatedCity[cityIndex].city = e.value;
      setCollege({ ...college, acad: updatedCity });
    } else {
      const updatedCity = [...college.acad];
      updatedCity[cityIndex].city = '';
      setCollege({ ...college, acad: updatedCity });
    }
  };

  const handleInputCityData = (e:any) => {
    if (e.length > 2) {
      setLoaderCity(true);
      dispatch(getCityListReq({
        requestType: APIHeader.REQ_GET_ALL_CITY_META,
        userType: UserType.CX,
        token: tokenData,
        limit: ApiLIMIT.LIMIT,
        searchData: e,
      }));
    }
  };

  const handleSubmitAcad = (e: any) => {
    e.preventDefault();
    setLoaderAcad(true);
    dispatch(addAcadReq({
      inputBody: college,
      requestType: APIHeader.REQ_ADD_ACADEMY,
      userType: UserType.CX,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (collegeResponse.error && collegeResponse.message !== '') {
      setLoaderAcad(false);
      setAlertMsg(collegeResponse.message);
      setErrorShowAlert(true);
    }
  }, [collegeResponse.error, collegeResponse.message]);

  React.useEffect(() => {
    if (!collegeResponse.error && collegeResponse.message !== '') {
      setLoaderAcad(false);
      Promise.resolve(dispatch(clearAddAcadReq()))
        .then(() => {
          setCollege({
            acad: [{
              nme: '',
              univ: '',
              grp: '',
              addr: '',
              tr: 0,
              logo: '',
              city: '',
              cntPh: '',
              cntEId: '',
            }],
          });
          setAlertMsg(collegeResponse.message);
          setSuccessShowAlert(true);
        });
    }
  }, [collegeResponse.error, collegeResponse.message]);

  return (
    <Row className="pt-3">
      { loaderAcad ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 8, order: 1 }} md={{ size: 8, order: 1 }} xs={{ order: 1 }}>
        <Form onSubmit={handleSubmitAcad}>
          <Card className="form-card">
            <CardHeader className="form-card-header">
              Onboard New Academy
            </CardHeader>
            <CardBody>
              {college.acad.map((academy, indx) => (
                <Row key={`acad-${indx.toString()}`}>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Academy Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Academy Name"
                        type="text"
                        name="nme"
                        required
                        value={academy.nme}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Academy Logo Url<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Academy Logo Url"
                        type="text"
                        name="logo"
                        required
                        value={academy.logo}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Academy Group<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Academy Group"
                        type="text"
                        name="grp"
                        required
                        value={academy.grp}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">University Name<span className="mandate-star">*</span></h6>
                      <Select
                        options={univNameoptions}
                        onChange={(e) => handleSelectUnivData(e, indx)}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            position: 'relative',
                          }),
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#F6B26B' },
                            border: '1px solid #DDE0E4',
                            minHeight: '50px',
                            borderRadius: '5px',
                            padding: 1,
                            fontSize: '15px',
                            fontWeight: 400,
                            color: '#575656',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No University Found'}
                        isLoading={loaderUniv}
                        placeholder="Enter University Name"
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Contact Phone(Include Country Code)</h6>
                      <Input
                        placeholder="Enter Phone Number"
                        type="text"
                        name="cntPh"
                        value={academy.cntPh}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Contact Email</h6>
                      <Input
                        placeholder="Enter Contact Email"
                        type="text"
                        name="cntEId"
                        required
                        value={academy.cntEId}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-input"
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">College City Tier<span className="mandate-star">*</span></h6>
                      <Select
                        options={tierOptions}
                        onChange={(e) => handleSelectTierData(e, indx)}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            position: 'relative',
                          }),
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#F6B26B' },
                            border: '1px solid #DDE0E4',
                            minHeight: '50px',
                            borderRadius: '5px',
                            padding: 1,
                            fontSize: '15px',
                            fontWeight: 400,
                            color: '#575656',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No Tier Found'}
                        isLoading={loaderUniv}
                        placeholder="Enter College City Tier"
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="10" xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Academy Address<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter Academy Address"
                        type="textarea"
                        name="addr"
                        required
                        value={academy.addr}
                        onChange={(event) => handleChangeAcad(indx, event)}
                        className="campus-text-area"
                      />
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="text-right-login-campus-register pt-4 mx-1">
                      <h6 className="input-header-text">Academy City<span className="mandate-star">*</span></h6>
                      <Select
                        options={cityNameoptions}
                        onInputChange={handleInputCityData}
                        onChange={(e) => handleSelectCityData(e, indx)}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            position: 'relative',
                          }),
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#F6B26B' },
                            border: '1px solid #DDE0E4',
                            minHeight: '50px',
                            borderRadius: '5px',
                            padding: 1,
                            fontSize: '15px',
                            fontWeight: 400,
                            color: '#575656',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No Academy City Found'}
                        isLoading={loaderCity}
                        placeholder="Enter Academy City Name"
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end">
                <Button className="campus-button-oval mx-1">
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
      <Col lg={{ size: 4, order: 2 }} md={{ size: 4, order: 2 }} xs={{ order: 2 }}>
        <Row className="margin-top-bottom margin-top-more">
          <Col lg="11" xs="12">
            <Card className="form-card-shadow">
              <CardHeader className="card-header-border">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span>Do you Know ?</span>
              </CardHeader>
              <CardBody>
                Get the details here - About Me
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddColleges;
