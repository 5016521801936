import { putJSON } from '../../utils';
import { getConfig } from '../../utils/config/config';
import { UserProfilePutOptions, UserProfilePutResponse } from './update-profile.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();

export const UserProfilePutRequest = ({
  requestType, userType, inputBody, token,
}: UserProfilePutOptions): Promise<UserProfilePutResponse> => {
  if (token) {
    return putJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, token, requestType, inputBody as unknown as BodyInit);
  }
  return putJSON(`${CAMPUSXCEL_API_ROOT}?userType=${userType}`, '', requestType, inputBody as unknown as BodyInit);
};
