import { ListOrgResponse, ListOrgResults } from './list-org.service.types';
import { OrgResult, OrgResultInfo } from './list-org.types';

const AppTransform: (input: OrgResult) => OrgResultInfo = (org) => ({
  metaId: org.MetaId,
  nme: org.nme,
});
export const ListOrgTransform: (response: ListOrgResponse) => ListOrgResults = (listOrgResults) => {
  const { data, is_error, message } = listOrgResults;
  const result: ListOrgResults = {
    tCnt: 0,
    fCnt: 0,
    orgData: [],
    orgInfo: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.orgData = data.orgData;
    result.orgInfo = data.orgData.map((org: OrgResult) => AppTransform(org));
  }
  return result;
};
