import { ListMentorResponse, ListMentorResults } from './list-mentor.service.types';

export const ListMentorTransform: (response: ListMentorResponse) => ListMentorResults = (listMentorResults) => {
  const { data, is_error, message } = listMentorResults;
  const result: ListMentorResults = {
    tCnt: 0,
    fCnt: 0,
    mntrData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.mntrData = data.mntrData;
  }
  return result;
};
