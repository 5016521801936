import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import {
  Row, Col, CardBody, CardFooter, Card, Input, Button, InputGroup, InputGroupText, NavLink, Form,
} from 'reactstrap';
import './login.scss';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import { getForgotPassword, getLoginToken, getProfile } from '../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../utils/constants';
import { errorAlert, successAlert } from '../../../utils/alert';
import { LoaderData } from '../../../utils/loader';
import { setTokenToLocalStorage, setUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
// import { LoaderData } from '../../../utils/loader';

export const LoginCampusXcel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOTP, setIsOTP] = React.useState(false);
  const [isPassword, setIsPassword] = React.useState(true);
  const [loginOTP, setLoginOTP] = React.useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loginPassKey, setLoginPassKey] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const [inputOTP, setInputOTP] = useState('');
  const [loginOTPUserName, setLoginOTPUserName] = useState('');
  const [forgotUserName, setForgotUserName] = useState('');
  const [isOTPAll, setIsOTPAll] = useState(true);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const loginProfileData = useSelector((state: RootState) => state.campusXcel.profileData);
  const forgotPasswordData = useSelector((state: RootState) => state.campusXcel.forgotPassKeyData);

  const homePage = () => {
    history('/');
    window.scrollTo(0, 0);
  };
  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setIsOTP(false);
    setIsPassword(false);
    setLoginOTP(false);
  };
  const handleSwitchToPassword = () => {
    setIsForgotPassword(false);
    setIsOTP(false);
    setIsPassword(true);
    setLoginOTP(false);
  };
  const handleSwitchToOTP = () => {
    setIsForgotPassword(false);
    setIsOTP(true);
    setIsPassword(false);
    setLoginOTP(false);
  };
  const handlePassKeyChange = (e: any) => {
    setLoginPassKey(e.target.value);
  };
  const handleUserNameChange = (e: any) => {
    setLoginUserName(e.target.value);
  };
  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };
  const handleOTPUserNameChange = (e: any) => {
    setLoginOTPUserName(e.target.value);
  };
  const handleForgotUserNameChange = (e: any) => {
    setForgotUserName(e.target.value);
  };

  const handleOTPChange = (e: any) => {
    setInputOTP(e);
    if (e.toString().length === 4 && e.toString().match(/^\d{4}$/) !== null) {
      setIsOTPAll(false);
    } else {
      setIsOTPAll(true);
    }
  };
  const handlePasswordSubmit = async (e:any) => {
    e.preventDefault();
    if (loginUserName !== '' && loginPassKey !== '') {
      setIsLoader(true);
      dispatch(getLoginToken({
        userName: loginUserName,
        passKey: loginPassKey,
        isPublic: false,
        userType: UserType.COMMN,
        userReqFor: UserType.CX,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  };
  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  const handleSubmitOTP = async (e:any) => {
    e.preventDefault();
    if (loginOTPUserName !== '') {
      if (!isLoader) {
        setIsLoader(!isLoader);
      }
      dispatch(getLoginToken({
        userName: loginOTPUserName,
        userType: UserType.COMMN,
        isPublic: false,
        userReqFor: UserType.CX,
        requestType: APIHeader.REQ_GENERATE_OTP,
        passKey: '',
      }));
    }
  };

  const handleLoginByOTP = () => {
    if (!isLoader) {
      setIsLoader(!isLoader);
    }
    dispatch(getLoginToken({
      userName: loginOTPUserName,
      isPublic: false,
      userType: UserType.COMMN,
      userReqFor: UserType.CX,
      requestType: APIHeader.REQ_VERIFY_OTP,
      passKey: inputOTP,
    }));
  };
  const handleForgotPasskeySubmit = (e:any) => {
    e.preventDefault();
    setIsLoader(!isLoader);
    dispatch(getForgotPassword({
      userName: forgotUserName,
      userType: UserType.PUBLIC,
      userReqFor: UserType.CX,
      requestType: APIHeader.REQ_FORGOT_PASSWORD,
    }));
  };

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '') {
      setIsLoader(false);
      if (loginTokenData.token === 'OTP Mail sent successfully') {
        setIsForgotPassword(false);
        setIsOTP(false);
        setIsPassword(false);
        setLoginOTP(true);
      } else {
        setTokenToLocalStorage(loginTokenData.token);
        dispatch(getProfile({
          userType: UserType.COMMN,
          userReqFor: UserType.CX,
          requestType: APIHeader.REQ_USER_PROFILE_INFO,
          token: loginTokenData.token,
        }));
      }
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    if (loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginProfileData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.profileData.fNme !== '') {
      setIsLoader(false);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      if (loginProfileData.profileData.isAdm && loginProfileData.profileData.UserTp === 'CX') {
        history('/cx/admin/dashboard');
      } else if (!loginProfileData.profileData.isAdm && loginProfileData.profileData.UserTp === 'CX') {
        history('/cx/dashboard');
      } else {
        setErrorShowAlert(true);
        setAlertMsg('Incorrect User');
      }
    }
  }, [loginProfileData.profileData.fNme, loginTokenData.isError, loginProfileData.profileData.UserTp]);

  React.useEffect(() => {
    if (forgotPasswordData.isError && forgotPasswordData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(forgotPasswordData.message);
    }
  }, [forgotPasswordData.message, forgotPasswordData.isError]);

  React.useEffect(() => {
    if (!forgotPasswordData.isError && forgotPasswordData.forgotPasswordData !== '') {
      setIsLoader(false);
      setSuccessShowAlert(true);
      setAlertMsg(forgotPasswordData.forgotPasswordData);
    }
  }, [forgotPasswordData.isError, forgotPasswordData.forgotPasswordData]);

  return (
    <div className="login-align-vertical">
      { isLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(true, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Card className="login-card">
        <div className="image-top-left" />
        <div className="image-bottom-right" />
        <CardBody>
          <Row className="pt-2">
            <Col lg={{ size: 7, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
              <div className="align-start pt-2 mx-4">
                <NavLink to="/#" className="nav-logo" onClick={homePage}>
                  <img style={{ height: '45px', width: 'auto' }} src="/campusXcel.png" alt="campusXcelLogo" />
                </NavLink>
              </div>
              <div className="align-center pt-5 mx-3">
                <span className="sign-up-header">
                  {isForgotPassword ? 'Reset Password' : 'Login'}
                </span>
              </div>
              {isPassword
                ? (
                  <div className="pt-5">
                    <div className="align-end mx-3">
                      <Card
                        onClick={handleSwitchToOTP}
                        className="campus-radio-box"
                      >
                        <span className="campus-gender-text">
                          <img src="/images/login-register/pin.png" alt="..." />
                          <span className="mx-1 display-data-none">
                            OTP
                          </span>
                        </span>
                      </Card>
                    </div>
                    <Form onSubmit={handlePasswordSubmit}>
                      <div className="text-right-login-campus mx-3">
                        <h6 className="input-header-text">UserInfo<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Email Id / Username"
                          type="text"
                          required
                          className="campus-input mx-1"
                          value={loginUserName}
                          onChange={handleUserNameChange}
                        />
                      </div>
                      <div className="text-right-login-campus mx-3 pt-4">
                        <h6 className="input-header-text">Password<span className="mandate-star">*</span></h6>
                        <InputGroup>
                          <Input
                            placeholder="Password"
                            type={passwordShown ? 'text' : 'password'}
                            required
                            value={loginPassKey}
                            onChange={handlePassKeyChange}
                            className="campus-input mx-1"
                          />
                          <InputGroupText className="campus-input cursor-pointer" onClick={handleClickShowPassword}>
                            {passwordShown
                              ? (<FontAwesomeIcon icon={faEye} className="pwd-eye-icon" />)
                              : (<FontAwesomeIcon icon={faEyeSlash} className="pwd-eye-close-icon" />
                              )}
                          </InputGroupText>
                        </InputGroup>
                      </div>
                      <div className="align-end pt-1">
                        <NavLink className="forgot-password-text cursor-pointer mx-3" to="#" onClick={handleForgotPassword}>Forgot password ?</NavLink>
                      </div>
                      <div className="text-right-login-campus align-end pt-4">
                        <Button className="campus-button-oval mx-1">
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
              {isOTP
                ? (
                  <div className="pt-5">
                    <div className="align-end mx-3">
                      <Card
                        onClick={handleSwitchToPassword}
                        className="campus-radio-box"
                      >
                        <span className="campus-gender-text">
                          <img src="/images/login-register/pin.png" alt="..." />
                          <span className="mx-1 display-data-none">
                            PASSWORD
                          </span>
                        </span>
                      </Card>
                    </div>
                    <Form onSubmit={handleSubmitOTP}>
                      <div className="text-right-login-campus mx-3">
                        <h6 className="input-header-text">UserInfo<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Email Id / Username"
                          type="text"
                          required
                          className="campus-input mx-1"
                          onChange={handleOTPUserNameChange}
                        />
                      </div>
                      <div className="align-end pt-1">
                        <NavLink className="forgot-password-text cursor-pointer mx-3" to="#" onClick={handleForgotPassword}>Forgot password ?</NavLink>
                      </div>
                      <div className="text-right-login-campus align-end pt-4">
                        <Button className="campus-button-oval mx-1">
                          Generate OTP
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
              {isForgotPassword
                ? (
                  <div className="pt-5">
                    <Form onSubmit={handleForgotPasskeySubmit}>
                      <div className="text-right-login-campus mx-3">
                        <h6 className="input-header-text">UserInfo<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Email Id / Username"
                          type="text"
                          required
                          value={forgotUserName}
                          onChange={handleForgotUserNameChange}
                          className="campus-input mx-1"
                        />
                      </div>
                      <div className="align-end pt-1">
                        <NavLink className="forgot-password-text cursor-pointer mx-3" to="#" onClick={handleSwitchToPassword}>Back to login ?</NavLink>
                      </div>
                      <div className="text-right-login-campus align-end pt-4">
                        <Button className="campus-button-oval mx-1">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
              {loginOTP
                ? (
                  <div className="pt-5">
                    <div className="text-right-login-campus mx-3">
                      <div className="align-center pt-3">
                        <h6 className="input-header-text">Enter verification code, sent on your email</h6>
                      </div>
                      <div className="align-center pt-3">
                        <OtpInput
                          value={inputOTP}
                          onChange={handleOTPChange}
                          numInputs={4}
                          separator={(
                            <span> </span>
                              )}
                          focusStyle={{
                            border: '1px solid #F6B26B',
                            outline: 'none',
                          }}
                          inputStyle={{
                            width: '2.7rem',
                            height: '2.7rem',
                            margin: '0.5rem',
                            fontSize: '1.2rem',
                            borderRadius: 4,
                            border: '1px solid #DDE0E4',
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-right-login-campus align-end pt-4">
                      <Button disabled={isOTPAll} className="campus-button-oval mx-1" onClick={handleLoginByOTP}>
                        Login
                      </Button>
                    </div>
                  </div>
                ) : null}
            </Col>
            <Col lg={{ size: 5, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }} className="vertical-align">
              <div className="align-center pt-2">
                <img
                  alt="..."
                  height="150px"
                  src="images/login-register/login.png"
                />
              </div>
              <div className="align-center pt-4 my-2">
                <span className="login-sign-up-text-header">campusXcel Admin</span>
              </div>
              <div className="align-start pt-4 ml-1">
                <FontAwesomeIcon icon={faCircleCheck} className="login-sign-up-icon mr-1 pt-1" />
                <span className="login-sign-up-text">Join the campusXcel and be a part of an innovative community</span>
              </div>
              <div className="align-start pt-4 ml-1">
                <FontAwesomeIcon icon={faCircleCheck} className="login-sign-up-icon mr-1 pt-1" />
                <span className="login-sign-up-text">Manage your Students in better way</span>
              </div>
              <div className="align-start pt-4 ml-1">
                <FontAwesomeIcon icon={faCircleCheck} className="login-sign-up-icon mr-1 pt-1" />
                <span className="login-sign-up-text">Showcase your college across everywhere via this platform </span>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="login-card-header-footer">
          <div className="align-end">
            <span className="mx-1 already-text">Terms &amp; Use</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default LoginCampusXcel;
