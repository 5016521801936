import React from 'react';
import {
  faUserCheck,
  faUserCircle,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import './mentors.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { getTokenFromLocalStorage } from '../../../utils/service/localstorage-service';
import { APIHeader, ApiLIMIT, UserType } from '../../../utils/constants';
import { LoaderData } from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';
import { MentorData } from '../../../services/mentors/mentor-list/list-mentor.types';
import { clearMentorStatusListReq, getMentorStatusListReq } from '../../../store/campusXcel/actions';
import { ListMentorsStatus } from './mentors-status.component';

export const Mentors: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const mentorData = useSelector((state: RootState) => state.campusXcel.getMentorList);
  const [loaderMentors, setLoaderMentors] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('pending');
  const [mentorListData, setMentorListData] = React.useState<MentorData>({
    tCnt: 0,
    fCnt: 0,
    mntrData: [],
  });

  const handlePendingList = () => {
    setActiveTab('pending');
  };
  const handleApprovedList = () => {
    setLoaderMentors(true);
    Promise.resolve(
      dispatch(clearMentorStatusListReq()),
    ).then(() => {
      dispatch(getMentorStatusListReq({
        userType: UserType.CX,
        requestType: APIHeader.REQ_GET_ALL_MENTORS,
        limit: ApiLIMIT.LIMIT,
        token: tokenData,
        searchData: '',
        status: 'APPR',
      }));
    });
    setActiveTab('approved');
  };
  const handleRejectedList = () => {
    setLoaderMentors(true);
    Promise.resolve(
      dispatch(clearMentorStatusListReq()),
    ).then(() => {
      dispatch(getMentorStatusListReq({
        userType: UserType.CX,
        requestType: APIHeader.REQ_GET_ALL_MENTORS,
        limit: ApiLIMIT.LIMIT,
        token: tokenData,
        searchData: '',
        status: 'NACC',
      }));
    });
    setActiveTab('rejected');
  };
  React.useEffect(() => {
    if (activeTab === 'pending') {
      setLoaderMentors(true);
      Promise.resolve(
        dispatch(clearMentorStatusListReq()),
      ).then(() => {
        dispatch(getMentorStatusListReq({
          userType: UserType.CX,
          requestType: APIHeader.REQ_GET_ALL_MENTORS,
          limit: ApiLIMIT.LIMIT,
          token: tokenData,
          searchData: '',
          status: 'PADM',
        }));
      });
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (mentorData.isError && mentorData.message !== '') {
      setLoaderMentors(false);
      setAlertMsg(mentorData.message);
      setErrorShowAlert(true);
    }
  }, [mentorData.isError, mentorData.message]);

  React.useEffect(() => {
    if (!mentorData.isError && mentorData.message === '') {
      setLoaderMentors(false);
      if (mentorData.mntrData !== undefined) {
        setMentorListData(mentorData);
      }
    }
  }, [mentorData.isError, mentorData.message, mentorData.mntrData]);

  return (
    <Row>
      { loaderMentors ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col xs="12">
        <Nav tabs className="mentors-jobs-tab">
          <div className="mentors-row-jobs-tab">
            <div className="mentors-column-jobs-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink className="mentors-jobs-nav-tabs" active={activeTab === 'pending'} onClick={handlePendingList}>
                  <FontAwesomeIcon icon={faUserCircle} className="mentors-tab-jobs-icon" />
                  <span>Pending</span>
                </NavLink>
              </NavItem>
            </div>
            <div className="mentors-column-jobs-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="mentors-jobs-nav-tabs" active={activeTab === 'rejected'} onClick={handleRejectedList}>
                  <FontAwesomeIcon icon={faUserSlash} className="mentors-tab-jobs-icon" />
                  <span className="mentors-tab-text">Rejected</span>
                </NavLink>
              </NavItem>
            </div>
            <div className="mentors-column-jobs-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="mentors-jobs-nav-tabs" active={activeTab === 'approved'} onClick={handleApprovedList}>
                  <FontAwesomeIcon icon={faUserCheck} className="mentors-tab-jobs-icon" />
                  <span className="mentors-tab-text">Approved</span>
                </NavLink>
              </NavItem>
            </div>
          </div>
          <div className="mentors-row-jobs-tab-mobile">
            <div className="mentors-column-jobs-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink id="pendingId" className="mentors-jobs-nav-tabs" active={activeTab === 'pending'} onClick={handlePendingList}>
                  <FontAwesomeIcon icon={faUserCircle} className="mentors-tab-jobs-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="pendingId">
                  Mentor Pending
                </UncontrolledTooltip>
              </NavItem>
            </div>
            <div className="mentors-column-jobs-tab-right">
              <NavItem className="jobs-pointer-campus">
                <NavLink id="rejectedId" className="mentors-jobs-nav-tabs" active={activeTab === 'rejected'} onClick={handleRejectedList}>
                  <FontAwesomeIcon icon={faUserSlash} className="mentors-tab-jobs-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="rejectedId">
                  Mentor Rejected
                </UncontrolledTooltip>
              </NavItem>
            </div>
            <div className="mentors-column-jobs-tab-right">
              <NavItem className="jobs-pointer-campus">
                <NavLink id="approvedId" className="mentors-jobs-nav-tabs" active={activeTab === 'approved'} onClick={handleApprovedList}>
                  <FontAwesomeIcon icon={faUserCheck} className="mentors-tab-jobs-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="approvedId">
                  Mentor Approved
                </UncontrolledTooltip>
              </NavItem>
            </div>
          </div>
        </Nav>
      </Col>
      <Col xs="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="pending">
            <ListMentorsStatus mentorListData={mentorListData} />
          </TabPane>
          <TabPane tabId="approved">
            <ListMentorsStatus mentorListData={mentorListData} />
          </TabPane>
          <TabPane tabId="rejected">
            <ListMentorsStatus mentorListData={mentorListData} />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default Mentors;
