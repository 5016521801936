import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  Row, Col, Card, CardBody, Form, CardHeader, Input, InputGroup, InputGroupText,
} from 'reactstrap';
import { faCaretUp, faCaretDown, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';

export const AboutMe: React.FC = () => {
  const userProfile = getUserProfileSessionStorage();
  const [activeName, setActiveName] = useState('personal');

  const handleCollapseClick = (activeString: any) => {
    setActiveName(activeString);
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   setLoaderProfile(true);
  //   dispatch(userProfilePutReq({
  //     inputBody: {},
  //     requestType: APIHeader.REQ_CANDIDATE_ABOUT_ME,
  //     userType: UserType.CMPS,
  //     token: tokenData,
  //   }));
  // };

  return (
    <Row className="pt-3">
      <Col lg={{ size: 6, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
        <Form>
          <Card className="form-card">
            <CardHeader className="form-card-header">
              Update about myself
            </CardHeader>
            <CardBody>
              <Card className="collapse-bg form-card-border-none my-2">
                <CardHeader className="form-collapse-card-header cursor-pointer" onClick={() => handleCollapseClick('personal')}>
                  <Row>
                    <Col lg="11" md="10" xs="10">
                      Personal Details
                    </Col>
                    <Col lg="1" md="2" xs="2">
                      <FontAwesomeIcon className="collapse-icon" icon={activeName === 'personal' ? faCaretUp : faCaretDown} />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapsible
                // key={proj.prjNme}
                  open={activeName === 'personal'}
                  trigger={activeName ? '' : ''}
                  onOpening={() => setActiveName('personal')}
                >
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="input-header-text">First Name<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Your First Name"
                      type="text"
                      name="fNme"
                      value={userProfile && JSON.parse(userProfile).profileData.fNme}
                      readOnly
                      className="campus-input"
                    />
                  </div>
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="input-header-text">Last Name<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Your Last Name"
                      type="text"
                      name="lNme"
                      value={userProfile && JSON.parse(userProfile).profileData.lNme}
                      readOnly
                      // onChange={handleMyProfile}
                      className="campus-input"
                    />
                  </div>
                </Collapsible>
              </Card>
              <Card className="collapse-bg form-card-border-none my-2">
                <CardHeader className="form-collapse-card-header cursor-pointer" onClick={() => handleCollapseClick('contact')}>
                  <Row>
                    <Col lg="11" md="10" xs="10">
                      Contact Information
                    </Col>
                    <Col lg="1" md="2" xs="2">
                      <FontAwesomeIcon className="collapse-icon cursor-pointer" icon={activeName === 'contact' ? faCaretUp : faCaretDown} />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapsible
                // key={proj.prjNme}
                  open={activeName === 'contact'}
                  trigger={activeName ? '' : ''}
                  onOpening={() => setActiveName('contact')}
                >
                  <div className="text-right-login-campus-register pt-4">
                    <h6 className="input-header-text">Contact Email<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Your Email Id"
                      type="text"
                      name="eId"
                      value={userProfile && JSON.parse(userProfile).profileData.eId}
                      disabled
                      // onChange={handleMyProfile}
                      className="campus-input"
                    />
                  </div>
                  <div className="text-right-login-campus-register pt-4">
                    <span className="input-header-text">Phone<span className="mandate-star">*</span></span>
                    <InputGroup>
                      <InputGroupText className="campus-input">
                        +91
                        {/* name="dialCd"
                          value={registerCMPSData.dialCd}
                          disabled={registerLoader}
                          onChange={handleFieldChangeRegister} */}
                      </InputGroupText>
                      <Input
                        placeholder="Enter your Phone Number"
                        type="number"
                        disabled
                        name="ph"
                        value={userProfile && JSON.parse(userProfile).profileData.ph}
                        // onChange={handleMyProfile}
                        className="campus-input mx-1"
                      />
                    </InputGroup>
                    <h6 className="text-lite-grey mx-1 pt-1">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</h6>
                  </div>
                </Collapsible>
              </Card>
            </CardBody>
            {/* <CardFooter className="form-card-footer">
              <div className="align-end">
                <Button className="campus-button-oval mx-1">
                  Save
                </Button>
              </div>
            </CardFooter> */}
          </Card>
        </Form>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <Row className="align-end">
          <Col xs="10">
            <Card className="form-card-shadow">
              <CardHeader className="card-header-border">
                <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faLightbulb} />
                <span>Do you Know ?</span>
              </CardHeader>
              <CardBody>
                Get the details here - About Me
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AboutMe;
