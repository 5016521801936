import { ListSkillResponse, ListSkillResults } from './list-skill.service.types';
import { SkillResult, SkillResultInfo } from './list-skill.types';

const AppTransform: (input: SkillResult) => SkillResultInfo = (skill) => ({
  metaId: skill.MetaId,
  nme: skill.nme,
});
export const ListSkillTransform: (response: ListSkillResponse) => ListSkillResults = (listSkillResults) => {
  const { data, is_error } = listSkillResults;
  const result: ListSkillResults = {
    tCnt: 0,
    fCnt: 0,
    skillData: [],
    skillInfo: [],
    isError: false,
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.skillData = data.skillData;
    result.skillInfo = data.skillData.map((skill: SkillResult) => AppTransform(skill));
  }
  return result;
};
