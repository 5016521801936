import { BatchPutResponse, BatchPutResults } from './update-batch.service.types';

export const BatchPutTransform: (response: BatchPutResponse) => BatchPutResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :BatchPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
