import { MentorAccessUpdateResponse, MentorAccessUpdateResults } from './mentor-update.service.types';

export const MentorAccessUpdateTransform: (response:MentorAccessUpdateResponse) => MentorAccessUpdateResults = (mentorAccessResults) => {
  const { data, is_error, message } = mentorAccessResults;
  const result: MentorAccessUpdateResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.data = data;
  }
  return result;
};
