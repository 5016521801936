import { ListPCandResponse, ListPCandResults } from './list-pcand.service.types';

export const ListPCandTransform: (response: ListPCandResponse) => ListPCandResults = (listPCandResults) => {
  const { data, is_error } = listPCandResults;
  const result: ListPCandResults = {
    tCnt: 0,
    fCnt: 0,
    cndData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = data.message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.cndData = data.cndData;
    result.message = 'success';
  }
  return result;
};
