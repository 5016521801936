import { JobPositionByIdResponse, JobPositionByIdResults } from './job-position-by-id.service.types';

export const JobPositionByIdTransform: (response: JobPositionByIdResponse) => JobPositionByIdResults = (jobPositionByIdResults) => {
  const { data, is_error, message } = jobPositionByIdResults;
  const result :JobPositionByIdResults = {
    jobData: {
      JobId: '',
      sDte: '',
      eduTp: [],
      isAbld: false,
      isPub: false,
      mjrSkls: [{
        nme: '',
        yr: 0,
      }],
      JobCd: '',
      pref: '',
      nme: '',
      logoUrl: '',
      jbTp: [],
      isFml: false,
      eDte: '',
      minSal: '',
      minExp: 0,
      maxExp: 0,
      maxSal: '',
      dep: '',
      hrTp: '',
      isEdu: false,
      useLogo: false,
      isHirClg: false,
      loc: [],
      orgNme: {
        nme: '',
        loc: [],
        web: '',
        abt: '',
      },
      rr: [],
      abtProj: [],
      mnrSkls: [],
    },
    isError: false,
    message: '',
  };

  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.jobData.JobId = data.JobId ?? '';
    result.jobData.sDte = data.sDte ?? '';
    result.jobData.eduTp = data.eduTp ?? [];
    result.jobData.isAbld = data.isAbld ?? false;
    result.jobData.isPub = data.isPub ?? false;
    result.jobData.mjrSkls = data.mjrSkls ?? [];
    result.jobData.JobCd = data.JobCd ?? '';
    result.jobData.pref = data.pref ?? '';
    result.jobData.nme = data.nme ?? '';
    result.jobData.logoUrl = data.logoUrl ?? '';
    result.jobData.jbTp = data.jbTp ?? [];
    result.jobData.isFml = data.isFml ?? false;
    result.jobData.eDte = data.eDte ?? '';
    result.jobData.minSal = data.minSal ?? '';
    result.jobData.minExp = data.minExp ?? 0;
    result.jobData.maxExp = data.maxExp ?? 0;
    result.jobData.maxSal = data.maxSal ?? '';
    result.jobData.dep = data.dep ?? '';
    result.jobData.hrTp = data.hrTp ?? '';
    result.jobData.isEdu = data.isEdu ?? false;
    result.jobData.useLogo = data.useLogo ?? false;
    result.jobData.isHirClg = data.isHirClg ?? false;
    result.jobData.loc = data.loc ?? [];
    result.jobData.orgNme.nme = data.orgNme.nme ?? '';
    result.jobData.orgNme.loc = data.orgNme.loc ?? [];
    result.jobData.orgNme.abt = data.orgNme.abt ?? '';
    result.jobData.orgNme.web = data.orgNme.web ?? '';
    result.jobData.rr = data.rr ?? [];
    result.jobData.abtProj = data.abtProj ?? [];
    result.jobData.mnrSkls = data.mnrSkls ?? [];
    result.isError = is_error;
  }
  return result;
};
