import { AcadAddResponse, AcadAddResults } from './add-new-acad.service.types';

export const AcadAddTransform: (response: AcadAddResponse) => AcadAddResults = (batchResults) => {
  const { data, is_error, message } = batchResults;
  const result :AcadAddResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = data;
  }
  return result;
};
