export enum ApiLIMIT{
    LIMIT = 30
}
export enum UserType {
    CORP='CORP',
    CMPS='CMPS',
    COMMN='COMMN',
    PUBLIC='PUBLIC',
    CX='CX'
}
export enum APIHeader{
  // Admin View
    REQ_GET_ALL_ACAD_META_ADM = 'get_all_acad_meta_adm',
  // Non Admin View
    REQ_ADD_CMPS_CANDIDATE = 'add_cx_candidate',
    REQ_ADD_OBJECTIVE = 'update_candidate_objective',
    REQ_FORM_DATA = 'form_data',
    REQ_IMG_DATA = 'image_data',
    REQ_GET_ALL_ACAD_META = 'get_all_acad_meta',
    USER_LOGIN = 'user_login',
    REQ_GENERATE_OTP = 'generate_otp',
    REQ_VERIFY_OTP = 'verify_otp',
    REQ_USER_PROFILE_INFO = 'user_profile_info',
    REQ_FORGOT_PASSWORD = 'forgot_password',
    REQ_FILE_URL = 'get_presigned_url',
    REQ_GET_ALL_CITY_META='get_all_city_s3',
    REQ_GET_CANDIDATE_ID = 'get_candidate_id',
    REQ_CANDIDATE_ABOUT_ME = 'update_candidate_about_me',
    REQ_UPDATE_PASSWORD = 'update_password',
    REQ_UPLOAD_FILES = 'upload_files',
    REQ_GET_ALL_ORG_META = 'get_all_org_meta',
    REQ_GET_ALL_SKILL_META = 'get_all_skill_meta',
    REQ_CANDIDATE_ORG_PROJECT = 'update_candidate_org_projects',
    REQ_DELETE_ORG_PROJECT = 'delete_org_project',
    REQ_GET_ALL_STRM_META='get_all_strm_meta',
    REQ_ADD_BATCH = 'add_acad_batch',
    REQ_GET_ALL_ACAD_BATCH = 'get_all_acad_batch',
    REQ_GET_ALL_PENDING_CAND = 'get_all_pending_candidates',
    REQ_UPDATE_PENDING_CAND = 'update_pending_candidate',
    REQ_ADD_ACADEMY = 'add_academy_meta',
    REQ_AGET_ALL_UNIV = 'get_all_univ_meta',
    REQ_ADD_CMPS_USR = 'add_acad_user',
    REQ_GET_ALL_MENTORS = 'get_all_mentors',
    REQ_UPDATE_MENTORS_STATUS = 'update_mentor_status',
}
export enum APIParam{
    DIALING_META = 'dialing_code_meta',
    DIFF_ABLED_META = 'diff_abled_meta',
    EXT_ORG_META = 'ext_org_meta',
    JOB_TITLE_META = 'job_title_meta',
    SKILLS_META = 'skills_meta',
    COLLEGE_META = 'college_meta',
    COURSE_META = 'course_meta',
    CITY_META = 'city_meta',
    AVATAR_IMAGE = 'AVATAR',
    VIDEO = 'VIDEO'
}

export const DiffAbledTypeInfo = [
  { label: 'Not Applicable', value: 'NONE' },
  { label: 'Blindness', value: 'BLD' },
  { label: 'Low-vision', value: 'LVS' },
  { label: 'Leprosy Cured Persons', value: 'LCP' },
  { label: 'Hearing Impairment (deaf and hard of hearing)', value: 'HIM' },
  { label: 'Locomotor Disability', value: 'LDT' },
  { label: 'Dwarfism', value: 'DWR' },
  { label: 'Intellectual Disability', value: 'IDT' },
  { label: 'Mental Illness', value: 'MIS' },
  { label: 'Autism Spectrum Disorder', value: 'ASD' },
  { label: 'Cerebral Palsy', value: 'CPE' },
  { label: 'Muscular Dystrophy', value: 'MDY' },
  { label: 'Chronic Neurological Conditions', value: 'CNC' },
  { label: 'Specific Learning Disabilities', value: 'SLD' },
  { label: 'Multiple Sclerosis', value: 'MSS' },
  { label: 'Speech and Language Disability', value: 'SDL' },
  { label: 'Thalassemia', value: 'THL' },
  { label: 'Hemophilia', value: 'HMA' },
  { label: 'Sickle Cell Disease', value: 'SCD' },
  { label: 'Multiple Disabilities Including Deaf Blindness', value: 'MDD' },
  { label: 'Acid Attack Victim', value: 'AAV' },
  { label: 'Parkinson s Disease', value: 'PSD' },
  { label: 'Unknown', value: 'UNK' },
];

export const CountryInfo = [
  { label: 'India', value: 'IN' },
  { label: 'United States of America', value: 'USA' },
  { label: 'United Kingdom', value: 'UK' },
];

export const ListCourseType = [
  { label: 'Post Graduation(PG)', value: 'PG' },
  { label: 'Under Graduate(UG)', value: 'UG' },
  { label: 'Masters', value: 'MASTERS' },
];

export const ListCityTier = [
  { label: 'Tier-1', value: 1 },
  { label: 'Tier-2', value: 2 },
  { label: 'Tier-3', value: 3 },
];

export const ListLoginAccess = [
  { label: 'Approve Access', value: 'APPR' },
  { label: 'Pending Approval', value: 'PADM' },
  { label: 'Block User', value: 'NACC' },
];
