import { ListStrmResponse, ListStrmResults } from './list-stream.service.types';
import { StrmResult, StrmResultInfo } from './list-stream.types';

const AppTransform: (input: StrmResult) => StrmResultInfo = (strm) => ({
  metaId: strm.MetaId,
  nme: strm.nme,
});
export const ListStrmTransform: (response: ListStrmResponse) => ListStrmResults = (listStrmResults) => {
  const { data, is_error } = listStrmResults;
  const result: ListStrmResults = {
    tCnt: 0,
    fCnt: 0,
    strmData: [],
    strmInfo: [],
    isError: false,
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.strmData = data.strmData;
    result.strmInfo = data.strmData.map((strm: StrmResult) => AppTransform(strm));
  }
  return result;
};
