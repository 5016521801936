import React from 'react';
import {
  faGraduationCap,
  faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import './campus.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { getTokenFromLocalStorage } from '../../../utils/service/localstorage-service';
import { APIHeader, ApiLIMIT, UserType } from '../../../utils/constants';
import { LoaderData } from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';
import { ListPendingCandidates } from './candidates/all-candidates.component';
import { CollegeDetails } from './colleges/college.component';
import { AcadData } from '../../../services/meta-data/list-acad/list-acad.types';
import { getAcadListReq } from '../../../store/campusXcel/actions';

export const Campus: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [collegeListData, setCollegeListData] = React.useState<AcadData>({
    tCnt: 0,
    fCnt: 0,
    acadData: [
      {
        city: '',
        MetaId: '',
        nme: '',
        addr: '',
        logo: '',
        cntPh: '',
        cntEId: '',
        univNme: '',
        univTp: '',
        cndCnt: 0,
      },
    ],
  });
  const collegeData = useSelector((state: RootState) => state.campusXcel.getAcadMetaList);
  const [loaderCollege, setLoaderCollege] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('college');
  const handleCandidateList = () => {
    setActiveTab('candidates');
  };
  const handleCollegeList = () => {
    setActiveTab('college');
  };
  React.useEffect(() => {
    if (activeTab === 'college') {
      setLoaderCollege(true);
      dispatch(getAcadListReq({
        userType: UserType.CX,
        requestType: APIHeader.REQ_GET_ALL_ACAD_META_ADM,
        limit: ApiLIMIT.LIMIT,
        token: tokenData,
        searchData: '',
      }));
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (collegeData.isError && collegeData.message !== '') {
      setLoaderCollege(false);
      setAlertMsg(collegeData.message);
      setErrorShowAlert(true);
    }
  }, [collegeData.isError, collegeData.message]);

  React.useEffect(() => {
    if (!collegeData.isError && collegeData.message === '') {
      setLoaderCollege(false);
      setCollegeListData(collegeData);
    }
  }, [collegeData.isError, collegeData.message, collegeData.acadData.length]);

  return (
    <Row>
      { loaderCollege ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col xs="12">
        <Nav tabs className="campus-jobs-tab">
          <div className="campus-row-jobs-tab">
            <div className="campus-column-jobs-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-jobs-nav-tabs" active={activeTab === 'college'} onClick={handleCollegeList}>
                  <FontAwesomeIcon icon={faGraduationCap} className="campus-tab-jobs-icon" />
                  <span>Academy</span>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-jobs-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-jobs-nav-tabs" active={activeTab === 'candidates'} onClick={handleCandidateList}>
                  <FontAwesomeIcon icon={faUsersBetweenLines} className="campus-tab-jobs-icon" />
                  <span className="campus-tab-text">Campus Candidates</span>
                </NavLink>
              </NavItem>
            </div>
          </div>
          <div className="campus-row-jobs-tab-mobile">
            <div className="campus-column-jobs-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink id="collegeId" className="campus-jobs-nav-tabs" active={activeTab === 'college'} onClick={() => setActiveTab('college')}>
                  <FontAwesomeIcon icon={faGraduationCap} className="campus-tab-jobs-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="collegeId">
                  Academy
                </UncontrolledTooltip>
              </NavItem>
            </div>
            <div className="campus-column-jobs-tab-right">
              <NavItem className="jobs-pointer-campus">
                <NavLink id="candidatesId" className="campus-jobs-nav-tabs" active={activeTab === 'candidates'} onClick={() => setActiveTab('candidates')}>
                  <FontAwesomeIcon icon={faUsersBetweenLines} className="campus-tab-jobs-icon" />
                </NavLink>
                <UncontrolledTooltip delay={0} target="candidatesId">
                  Campus  Candidates
                </UncontrolledTooltip>
              </NavItem>
            </div>
          </div>
        </Nav>
      </Col>
      <Col xs="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="college">
            <CollegeDetails collegeListData={collegeListData} />
          </TabPane>
          <TabPane tabId="candidates">
            <ListPendingCandidates />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default Campus;
