import { ListJobsResponse, ListJobsResults } from './list-jobs.service.types';
import { JobsResult } from './list-jobs.types';

const AppTransform: (input: JobsResult) => JobsResult = (job) => ({
  JobId: job.JobId,
  sDte: job.sDte,
  eduTp: job.eduTp,
  isAbld: job.isAbld,
  isPub: job.isPub,
  mjrSkls: job.mjrSkls,
  JobCd: job.JobCd,
  pref: job.pref,
  nme: job.nme,
  logoUrl: job.logoUrl,
  jbTp: job.jbTp,
  isFml: job.isFml,
  eDte: job.eDte,
  minSal: job.minSal,
  minExp: job.minExp,
  maxExp: job.maxExp,
  maxSal: job.maxSal,
  dep: job.dep,
  hrTp: job.hrTp,
  isEdu: job.isEdu,
  useLogo: job.useLogo,
  isHirClg: job.isHirClg,
  loc: job.loc,
  curSts: job.curSts,
  orgNme: job.orgNme,
});
export const ListJobsTransform: (response: ListJobsResponse) => ListJobsResults = (listJobsResults) => {
  const { data, is_error } = listJobsResults;
  const result: ListJobsResults = {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    // result.jobsData = data.jobsData;
    result.jobMessage = 'executed';
    result.jobsResult = data.jobsData.map((job: JobsResult) => AppTransform(job));
  }
  return result;
};
