import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import {
  Modal, ModalHeader, ModalBody, Col, Row, Button, UncontrolledTooltip, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faBars, faBell, faBoxesStacked, faCalendar, faChalkboardTeacher, faEnvelope, faGear, faGraduationCap, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  Routes, Route, NavLink, useLocation,
} from 'react-router-dom';
import {
  clearKeysFromLocalStorage, clearKeysFromSessionStorage, getProfileUrlSessionStorage, getTokenFromLocalStorage, getUserProfileSessionStorage,
} from '../../utils/service/localstorage-service';
import { CXUnauthorized } from '../unauthorized/unauthorized.component';
import './exclusive.scss';
import CandidateDashboard from '../../components/exclusive/dashboard/dashboard.component';
import MyProfile from '../../components/exclusive/profile/my-profile.component';
import { ModalPasskeyUpdate } from '../../components/exclusive/profile/passkey-reset/modal-reset-passkey.component';
import { SideDrawer } from '../../components/exclusive/side-drawer/side-drawer.component';
import Campus from '../../components/exclusive/campus/campus.component';
import Mentors from '../../components/exclusive/mentors/mentors.component';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<CandidateDashboard />} />
    <Route path="campus" element={<Campus />} />
    {/* <Route path="campus/:onboardingId/:onboardingCd" element={<CXUnauthorized />} /> */}
    <Route path="calendar" element={<CXUnauthorized />} />
    <Route path="profile" element={<MyProfile />} />
    <Route path="mentors" element={<Mentors />} />
  </Routes>
);
export const CampusXcelPrivateAdminComponent: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileSessionStorage();
  const getProfileUrl = getProfileUrlSessionStorage();
  const [imageURL, setImageUrl] = useState('/images/common/avatar.png');
  const [clickCXComponent, setClickCXComponent] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [resetPassKey, setResetPassKey] = useState(true);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [headerText, setHeaderText] = useState('');
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  const path = location.pathname.split('/');

  React.useEffect(() => {
    if (path[2] === 'dashboard') {
      setHeaderText('Dashboard');
    } else if (path[2] === 'campus') {
      setHeaderText('Campus');
    } else if (path[2] === 'mentors') {
      setHeaderText('Mentors');
    } else if (path[2] === 'calendar') {
      setHeaderText('Calendar');
    } else if (path[2] === 'profile') {
      setHeaderText('My Profile');
    }
  }, [path[2]]);

  React.useEffect(() => {
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          history('/login');
        }
      }
    }
  }, [tokenData]);

  React.useEffect(() => {
    if (getProfileUrl !== '') {
      setImageUrl(JSON.parse(getProfileUrl));
    }
  }, []);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const menuCloseIconClick = () => {
    setMenuCollapse(false);
  };

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClickCXComponent(!clickCXComponent);
  };
  // const handleToLink = (item: string) => {
  //   if(item === 'dashboard'){
  //     history('/dashboard');
  //     window.scrollTo(0, 0);
  //   } else if(item === 'dashboard'){
  //     history('/dashboard');
  //     window.scrollTo(0, 0);
  //   }
  // }

  return (
    <div className="campus-media">
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal isOpen={resetPassKey}>
              <ModalHeader toggle={passKeyModal} className="form-card-border-none mx-2 my-2" />
              <ModalBody className="modal-passkey-dashboard-campus">
                <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} />
              </ModalBody>
            </Modal>
          )
            : null}
          <div className="campus-display-device">
            {!menuCollapse ? (
              <Row>
                <Col lg="1">
                  <SideDrawer menuCollapse={menuCollapse} menuIconClick={menuIconClick} menuCloseIconClick={menuCloseIconClick} />
                </Col>
                <Col lg="11">
                  <Row className="campus-exclusive-header">
                    <Col lg="4" />
                    <Col lg="8" className="campus-float-right mx-2">
                      <img className="campus-image-logo" src="/campusXcel.png" alt="cxLogo" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <ExclusiveRoutes />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg="2"><SideDrawer menuCollapse={menuCollapse} menuIconClick={menuIconClick} menuCloseIconClick={menuCloseIconClick} /></Col>
                <Col lg="10">
                  <Row className="campus-exclusive-header">
                    <Col lg="2">
                      <span className="nav-header-text">{headerText}</span>
                    </Col>
                    <Col lg="10" className="campus-float-right mx-2">
                      <Button color="default" id="useremails"><FontAwesomeIcon icon={faEnvelope} className="campus-header-icon" /></Button>
                      <UncontrolledTooltip
                        target="useremails"
                      >
                        Emails
                      </UncontrolledTooltip>
                      <Button color="default" id="notifications"><FontAwesomeIcon icon={faBell} className="campus-header-icon" /></Button>
                      <UncontrolledTooltip
                        target="notifications"
                      >
                        Notifications
                      </UncontrolledTooltip>
                      <Button color="default" id="usersettings"><FontAwesomeIcon icon={faGear} className="campus-header-icon" /></Button>
                      <UncontrolledTooltip
                        target="usersettings"
                      >
                        Settings
                      </UncontrolledTooltip>
                      <img className="campus-image-logo" src="/campusXcel.png" alt="ccLogo" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <ExclusiveRoutes />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
          <div className="campus-display-device-mobile pt-2">
            <Navbar className="sticky-top pt-2 campus-nav-bg">
              <NavbarBrand href="/" className="pt-1 margin-logo">
                <img className="campus-image-logo-mobile" src="/campusXcel.png" alt="cxLogo" />
              </NavbarBrand>
              <NavbarToggler className="campus-toggle align-center" onClick={handleClick}>
                <img
                  alt="..."
                  className="img-fluid rounded-circle shadow-lg campus-avatar-user"
                  src={imageURL}
                />
                {changeIcon ? <FontAwesomeIcon icon={faXmark} className="campus-close-btn-mobile" /> : <FontAwesomeIcon icon={faBars} className="campus-open-btn-mobile" />}
              </NavbarToggler>
              <Collapse isOpen={clickCXComponent} navbar className="campus-collapse-bg">
                <Nav className="ml-auto">
                  <NavItem className="mptb campus-link-txt-border mx-3 pt-3">
                    <NavLink to="dashboard" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <FontAwesomeIcon className="campus-menu-icon" icon={faBoxesStacked} />
                      <span className="campus-link-txt ml-1">Dashboard</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3">
                    <NavLink to="campus" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <FontAwesomeIcon className="campus-menu-icon" icon={faGraduationCap} />
                      <span className="campus-link-txt ml-1">Campus</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3">
                    <NavLink to="mentors" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <FontAwesomeIcon className="campus-menu-icon" icon={faChalkboardTeacher} />
                      <span className="campus-link-txt ml-1">Mentors</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3">
                    <NavLink to="calendar" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <FontAwesomeIcon className="campus-menu-icon" icon={faCalendar} />
                      <span className="campus-link-txt ml-1">Calendar</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="mptb campus-link-txt-border mx-3">
                    <NavLink to="profile" onClick={handleClick} className="mx-3 campus-link-txt-decor">
                      <FontAwesomeIcon className="campus-menu-icon" icon={faAddressCard} />
                      <span className="campus-link-txt ml-1">My Profile</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="align-center my-4">
                  <Button onClick={() => { clearKeysFromLocalStorage(); clearKeysFromSessionStorage(); window.location.href = '/login'; }} className="campus-button-oval">
                    Logout
                  </Button>
                </div>
              </Collapse>
            </Navbar>
            <Row>
              <Col lg="12" className="pt-4">
                <ExclusiveRoutes />
              </Col>
            </Row>
          </div>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default CampusXcelPrivateAdminComponent;
