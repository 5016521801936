import React from 'react';
// import WDSFooter from '../../components/public/home/footer';
import { LoginCampusXcel } from '../../components/public/login/login';
// import Navbar from '../../components/public/navbar/campusXcel.navbar';
import './public.scss';

export const LoginComponent: React.FC = () => (
  <div>
    {/* <Navbar /> */}
    <LoginCampusXcel />
    {/* <div className="footer">
      <WDSFooter />
    </div> */}
  </div>
);

export default LoginComponent;
