import {
  faAddressCard,
  faArrowLeft,
  faBoxesStacked,
  faCalendar,
  faChalkboardTeacher,
  faChevronCircleLeft, faChevronCircleRight, faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  clearKeysFromLocalStorage,
  clearKeysFromSessionStorage,
  getProfileUrlSessionStorage, getTokenFromLocalStorage, getUserProfileSessionStorage, setProfileUrlStorage, setUserProfileSessionStorage,
} from '../../../utils/service/localstorage-service';
import './side-drawer.scss';
import { SideDrawerProps } from './side-drawer.type';
import { APIHeader, APIParam, UserType } from '../../../utils/constants';
import { getFileURLReq, getProfile } from '../../../store/campusXcel/actions';

export const SideDrawer: React.FC<SideDrawerProps> = ({ menuCollapse, menuIconClick, menuCloseIconClick }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userProfile = getUserProfileSessionStorage();
  const getProfileUrl = getProfileUrlSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [imageURL, setImageUrl] = useState('');
  const [emailId, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const fileUrlResponse = useSelector((state: RootState) => state.campusXcel.updateFileURL);
  const loginProfileResponse = useSelector((state: RootState) => state.campusXcel.profileData);
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = useState({
    dashboard: !!path[2].match('dashboard'),
    profile: !!path[2].match('profile'),
    calendar: !!path[2].match('calendar'),
    campus: !!path[2].match('campus'),
    mentors: !!path[2].match('mentors'),
  });
  const [defaultState, setDefaultState] = useState(true);
  React.useEffect(() => {
    if (defaultState) {
      if (path[3] === '') {
        setMenuItems({
          dashboard: true,
          profile: false,
          calendar: false,
          campus: false,
          mentors: false,
        });
      }
      if (path[3] === 'dashboard') {
        setMenuItems({
          dashboard: true,
          profile: false,
          calendar: false,
          campus: false,
          mentors: false,
        });
      }
      if (path[3] === 'campus') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: false,
          campus: true,
          mentors: false,
        });
      }
      if (path[3] === 'calendar') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: true,
          campus: false,
          mentors: false,
        });
      }
      if (path[3] === 'profile') {
        setMenuItems({
          dashboard: false,
          profile: true,
          calendar: false,
          campus: false,
          mentors: false,
        });
      }
      if (path[3] === 'mentors') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: false,
          campus: false,
          mentors: true,
        });
      }
      setDefaultState(true);
    }
  }, [defaultState, path[3]]);

  const handleOnClickMenuItem = (item: string) => {
    if (item === 'dashboard') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: true,
        profile: false,
        calendar: false,
        campus: false,
        mentors: false,
      });
    } else if (item === 'campus') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        campus: true,
        mentors: false,
      });
    } else if (item === 'profile') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: true,
        calendar: false,
        campus: false,
        mentors: false,
      });
    } else if (item === 'calendar') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: true,
        campus: false,
        mentors: false,
      });
    } else if (item === 'mentors') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        campus: false,
        mentors: true,
      });
    }
  };

  React.useEffect(() => {
    if (getProfileUrl !== '') {
      setImageUrl(JSON.parse(getProfileUrl));
    }
  }, []);

  React.useEffect(() => {
    if (emailId === '') {
      dispatch(getProfile({
        userType: UserType.COMMN,
        userReqFor: UserType.CX,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: tokenData,
      }));
    }
  }, [emailId]);

  React.useEffect(() => {
    if (loginProfileResponse.profileData.avtr !== '') {
      dispatch(getFileURLReq({
        requestType: APIHeader.REQ_FILE_URL,
        userReqFor: UserType.CMPS,
        bucketTp: APIParam.AVATAR_IMAGE,
        userType: UserType.COMMN,
        token: tokenData,
      }));
    }
  }, [loginProfileResponse.profileData.avtr]);

  React.useEffect(() => {
    if (fileUrlResponse.message !== '' && fileUrlResponse.isError) {
      setImageUrl('/images/common/avatar.png');
    } else if (fileUrlResponse.url !== '') {
      setProfileUrlStorage(JSON.stringify(fileUrlResponse.url));
      setImageUrl(fileUrlResponse.url);
    } else if (fileUrlResponse.url === '') {
      setImageUrl('/images/common/avatar.png');
    }
  }, [fileUrlResponse.message, fileUrlResponse.isError, fileUrlResponse.url]);

  React.useEffect(() => {
    if (emailId === '' || loginProfileResponse.profileData.eId !== '') {
      setUserProfileSessionStorage(JSON.stringify(loginProfileResponse));
      setFirstName(loginProfileResponse.profileData.fNme);
      setLastName(loginProfileResponse.profileData.lNme);
      setEmailId(loginProfileResponse.profileData.eId);
    }
  }, [emailId, loginProfileResponse.profileData.eId]);
  return (
    <div>
      <ProSidebar collapsed={!menuCollapse}>
        <SidebarHeader>
          <div className="align-center">
            {!menuCollapse
              ? (
                <div className="pt-3 my-2">
                  <img
                    alt="..."
                    className="cx-avatar-user-small img-fluid rounded-circle"
                    src={imageURL}
                  />
                </div>
              ) : (
                <div className="pt-3">
                  <div className="align-center">
                    <img
                      alt="..."
                      className="cx-avatar-user-large img-fluid rounded-circle"
                      src={imageURL}
                    />
                  </div>
                  <div className="align-center pt-2">
                    <h6 className="cx-text-avatar-name">{firstName}{' '}{lastName}</h6>
                  </div>
                  <div>
                    <h6 className="cx-text-avatar-designation">{userProfile && JSON.parse(userProfile).profileData.eId}</h6>
                  </div>
                  <div className="align-center">
                    <h6 className="cx-text-avatar-designation">ADMIN</h6>
                  </div>
                </div>
              )}
          </div>
          <div className="cx-side-border" />
          {menuCollapse ? <FontAwesomeIcon className="closemenu-cx" onClick={menuIconClick} icon={faChevronCircleLeft} /> : <FontAwesomeIcon className="closemenu-cx" onClick={menuIconClick} icon={faChevronCircleRight} />}
        </SidebarHeader>
        <SidebarContent className="overflow-tmp">
          <Menu iconShape="square">
            <MenuItem active={menuItems.dashboard} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faBoxesStacked} />} onClick={() => handleOnClickMenuItem('dashboard')}>
              <Link to="dashboard"><span className="cx-link-txt mx-2">Dashboard</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.campus} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faGraduationCap} />} onClick={() => handleOnClickMenuItem('campus')}>
              <Link to="campus"><span className="cx-link-txt">Campus</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.mentors} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faChalkboardTeacher} />} onClick={() => handleOnClickMenuItem('mentors')}>
              <Link to="mentors"><span className="cx-link-txt">Mentors</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.calendar} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faCalendar} />} onClick={() => handleOnClickMenuItem('calendar')}>
              <Link to="calendar"><span className="cx-link-txt">Calendar</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.profile} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faAddressCard} />} onClick={() => handleOnClickMenuItem('profile')}>
              <Link to="profile"><span className="cx-link-txt">My Profile</span></Link>
            </MenuItem>
          </Menu>
          <Menu iconShape="square" onClick={() => { clearKeysFromLocalStorage(); clearKeysFromSessionStorage(); window.location.href = '/login'; }}>
            <MenuItem className="menu-item-cx pt-1" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faArrowLeft} />}>
              <Link to="dashboard"><span className="cx-link-txt mx-2">Logout</span></Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default SideDrawer;
