import {
  faSearch, faFilter, faCaretDown, faCaretUp, faPhone, faEnvelope, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  Row, Col, Button, Input, InputGroup, Card, CardBody, CardHeader, CardImg, CardFooter, Modal, ModalBody,
} from 'reactstrap';
import { ListCollegeProps } from '../college.type';
import AddCMPSUser from '../add-cmps-user/campus-user.component';

export const ListColleges: React.FC<ListCollegeProps> = ({ collegeListData }) => {
  const [activeName, setActiveName] = useState('filterBatch');
  const [collegeId, setCollegeId] = useState('filterBatch');
  const [modelDetailsAddUser, setModelDetailsAddUser] = useState(false);
  const handleCollapseClick = (activeString: any) => {
    setActiveName(activeString);
  };
  const handleShowDetails = (acadId: string) => {
    setCollegeId(acadId);
    setModelDetailsAddUser(!modelDetailsAddUser);
  };

  return (
    <Row>
      <Row>
        <Col lg={{ size: 9, order: 1 }} md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
          <InputGroup>
            <Input className="campus-input-search" />
            <Button className="campus-input-search-icon mx-2">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Col lg={{ size: 9, order: 1 }} md={{ size: 8, order: 1 }} xs={{ order: 2 }}>
        <Row>
          <Col xs="12">
            {collegeListData && collegeListData.acadData && collegeListData.acadData.map((college, index) => (
              <Card className="form-card-shadow my-3" key={`batch-${index.toString()}`}>
                <CardHeader className="form-card-header">
                  <Row>
                    <Col lg="1" xs="3">
                      <CardImg src={college.logo} className="image-icon mx-1" />
                    </Col>
                    <Col lg="7" xs="9">
                      <div>
                        <span className="form-card-header-text">
                          {college.nme}
                        </span>
                        <div className="mx-1">
                          <span className="campus-contact-icon">{college.univNme} ({college.univTp})</span>
                        </div>
                        <div className="mx-1">
                          <span className="text-address">{college.addr}</span>
                        </div>
                        <div className="mx-1">
                          <span className="quest-menu-icon-size-sm">{college.city}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xs="12">
                      <div className="my-2 align-end">
                        <span className="quest-menu-icon-size-sm">{college.MetaId}</span>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="align-end">
                  <span className="text-address">User Count:
                    <span className="campus-contact-icon mx-2">{college.cndCnt}</span>
                  </span>
                </div>
                <CardBody>
                  <Row>
                    <Col lg="6" xs="12">
                      <div className="pt-1">
                        <FontAwesomeIcon icon={faEnvelope} className="campus-contact-icon mr" />
                        <span className="input-checkbox-text-grey">{college.cntEId === '' ? 'No Info' : college.cntEId}</span>
                      </div>
                    </Col>
                    <Col lg="4" xs="12">
                      <div className="pt-1">
                        <FontAwesomeIcon icon={faPhone} className="campus-contact-icon mr" />
                        <span className="input-checkbox-text-grey">{college.cntPh === '' ? 'No Info' : college.cntPh}</span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="form-card-footer">
                  <div className="align-end">
                    <Button className="campus-button-oval">
                      Details
                    </Button>
                    <Button className="campus-button-oval">
                      Edit
                    </Button>
                    <Button className="campus-button-oval" onClick={() => handleShowDetails(college.MetaId)}>
                      Add
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </Col>
        </Row>
      </Col>
      <Col lg={{ size: 3, order: 2 }} md={{ size: 4, order: 2 }} xs={{ order: 1 }}>
        <div className="">
          <Card className="form-card my-3">
            <CardHeader className="form-collapse-card-header cursor-pointer">
              <Row>
                <Col lg="10" md="10" xs="10">
                  <FontAwesomeIcon icon={faFilter} className="collapse-icon" />
                  <span className="campus-filter-header mx-1">Filter</span>
                </Col>
                <Col lg="2" md="2" xs="2">
                  {activeName === 'filterBatch'
                    ? (
                      <Button onClick={() => handleCollapseClick('none')} className="button-icon-none">
                        <FontAwesomeIcon className="collapse-icon" icon={faCaretUp} />
                      </Button>
                    )
                    : (
                      <Button onClick={() => handleCollapseClick('filterBatch')} className="button-icon-none">
                        <FontAwesomeIcon className="collapse-icon" icon={faCaretDown} />
                      </Button>
                    ) }
                </Col>
              </Row>
            </CardHeader>
            <Collapsible
              open={activeName === 'filterBatch'}
              trigger={activeName ? '' : ''}
              onOpening={() => setActiveName('filterBatch')}
            >
              <CardBody>
                <div>
                  Filter Data
                </div>
                <div className="align-center pt-2">
                  <Button className="campus-button-oval">
                    Clear All
                  </Button>
                </div>
              </CardBody>
            </Collapsible>
          </Card>
        </div>
      </Col>
      <Modal
        isOpen={modelDetailsAddUser}
        className="modal-right-to-left"
        fullscreen
      >
        <ModalBody className="mx-1">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="align-start">
                <Button className="button-icon" onClick={() => handleShowDetails('')}>
                  <FontAwesomeIcon className="close-button" icon={faXmarkSquare} />
                  <span className="dashboard-close-text mx-1">Close</span>
                </Button>
              </div>
            </Col>
            {/* <Col lg="8" md="11" xs="11">
                        <AddCMPSUser />
                      </Col> */}
          </Row>
          <div className="align-center1 ml">
            <AddCMPSUser collegeId={collegeId} />
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );
};
export default ListColleges;
