import { ListOrgProjResponse, ListOrgProjResults } from './list-org-proj.service.types';

export const ListOrgProjTransform: (response: ListOrgProjResponse) => ListOrgProjResults = (listOrgProjResults) => {
  const { data, is_error, message } = listOrgProjResults;
  const result: ListOrgProjResults = {
    orgProj: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.orgProj = data.orgProj;
  }
  return result;
};
