import {
  faCaretDown,
  faCaretUp,
  faFilter,
  faMessage,
  faPhone,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Button, Input, InputGroup, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion, Card, Label, CardHeader, CardBody,
} from 'reactstrap';
import './mentors.scss';
import Collapsible from 'react-collapsible';
import { RootState } from 'typesafe-actions';
import { ListStatusMentorProps } from './mentors.type';
import ListColleges from '../campus/colleges/all-colleges/list-college.component';
import {
  APIHeader, ApiLIMIT, ListLoginAccess, UserType,
} from '../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../utils/service/localstorage-service';
import { getMentorAccess } from '../../../store/campusXcel/actions';
import LoaderData from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';

export const ListMentorsStatus: React.FC<ListStatusMentorProps> = ({ mentorListData }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [mentorAccord, setMentorAccord] = useState<string>('mentor-0');
  const [mentorStatus, setMentorStatus] = useState<string>('');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [activeName, setActiveName] = useState('filterBatch');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [mentorAccess, setMentorAccess] = useState<boolean>(false);
  const mentorAccessData = useSelector((state: RootState) => state.campusXcel.updateMentorAccess);
  const [loaderMentorsAccess, setLoaderMentorsAccess] = React.useState(false);
  const accordianMentorToggle = (id:string) => {
    setMentorAccord(id);
  };

  const handleCollapseClick = (activeString: any) => {
    setActiveName(activeString);
  };

  const loginStsOptions = ListLoginAccess.map((loginSts) => ({
    label: loginSts.label,
    value: loginSts.value,
  }));

  const handleSelectLoginStsData = (e: any) => {
    if (e) {
      setMentorStatus(e.value);
    } else {
      setMentorStatus('');
    }
  };

  const handleCheckAccess = (event: any) => {
    setMentorAccess(event.target.checked);
  };
  const handleRejectReason = (event: any) => {
    setRejectReason(event.target.value);
  };

  const handleSubmitMentor = (usrCode:string) => {
    setLoaderMentorsAccess(true);
    dispatch(getMentorAccess({
      userType: UserType.CX,
      requestType: APIHeader.REQ_UPDATE_MENTORS_STATUS,
      limit: ApiLIMIT.LIMIT,
      token: tokenData,
      status: mentorStatus,
      inputBody: null,
      isAccess: mentorAccess,
      userCd: usrCode,
      reason: rejectReason,
    }));
  };
  React.useEffect(() => {
    if (mentorAccessData.error && mentorAccessData.message !== '') {
      setLoaderMentorsAccess(false);
      setAlertMsg(mentorAccessData.message);
      setErrorShowAlert(true);
    }
  }, [mentorAccessData.error, mentorAccessData.message]);

  React.useEffect(() => {
    if (!mentorAccessData.error && mentorAccessData.data !== '') {
      setLoaderMentorsAccess(false);
      setAlertMsg('Updated successfully. Mail sent');
      setSuccessShowAlert(true);
    }
  }, [mentorAccessData.error, mentorAccessData.data]);

  console.log('checkee', mentorListData);
  return (
    mentorListData && mentorListData.mntrData && mentorListData.mntrData.length === 0 ? <div className="align-center pt-5"><span className="text-mentor-header">No Record Found</span></div>
      : (
        <Row className="pt-3">
          { loaderMentorsAccess ? <LoaderData /> : null}
          {showSuccessAlert ? (
            successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
          ) : null}
          {showErrorsAlert ? (
            errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
          ) : null}
          <Row className="my-1">
            <Col lg={{ size: 9, order: 1 }} md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
              <InputGroup>
                <Input className="campus-input-search" />
                <Button className="campus-input-search-icon mx-2">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Col lg={{ size: 9, order: 1 }} md={{ size: 8, order: 1 }} xs={{ order: 2 }}>
            <Row>
              <Col xs="12">
                {mentorListData && mentorListData.mntrData && mentorListData.mntrData.map((mentor, index) => (
                  <Row className="pt-1 mx-1" key={`mentors-${index.toString()}`}>
                    <UncontrolledAccordion defaultOpen={mentorAccord}>
                      <AccordionItem onClick={() => accordianMentorToggle(`mentor-${index.toString()}`)} className="mentor-accord-desc">
                        <AccordionHeader targetId={`mentorD-${index.toString()}`}>
                          <div className="mentor-details">
                            <span className="text-mentor-header">{mentor.fNme} {mentor.lNme}</span>
                            <span className="text-mentor-sub-header mx-1">
                              <FontAwesomeIcon icon={faMessage} className="mentor-header-icon-msg mx-2" />
                              {mentor.mntrDtl.cEId}
                              {'  '}
                              <FontAwesomeIcon icon={faPhone} className="mentor-header-icon-msg mx-2" />
                              {mentor.dlCd} {mentor.mntrDtl.cPh}
                            </span>
                            <span className="text-mentor-sub-header pt-2">Industry Type</span>
                            {mentor.mntrDtl.expIndusTp.length !== 0
                              ? (
                                mentor.mntrDtl.expIndusTp.map((indusTp, prefIndx) => (
                                  <Card key={indusTp + prefIndx.toString()} className="mx-1 mentor-chip-box">
                                    <span className="mentor-chip-text">{indusTp}
                                    </span>
                                  </Card>
                                ))
                              ) : null }
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={`mentorD-${index.toString()}`}>
                          <Row>
                            <Col lg="12" xs="12">
                              <span className="text-mentor-sub-header-body mx-2">Sub Industry : </span>
                              {mentor.mntrDtl.expIndusSubTp.length !== 0
                                ? (
                                  mentor.mntrDtl.expIndusSubTp.map((subType, prefIndx) => (
                                    <span key={subType + prefIndx.toString()} className="mentor-chip-text mx-1">{subType},
                                    </span>
                                  ))
                                ) : null }
                            </Col>
                            <Col lg="12" xs="12" className="pt-1">
                              <span className="text-mentor-sub-header-body mx-2">Core Expectancies : </span>
                              {mentor.mntrDtl.expCoreSpec.length !== 0
                                ? (
                                  mentor.mntrDtl.expCoreSpec.map((coreSpec, prefIndx) => (
                                    <span key={coreSpec + prefIndx.toString()} className="mentor-chip-text mx-1">{coreSpec},
                                    </span>
                                  ))
                                ) : null }
                            </Col>
                            <Col lg="12" xs="12" className="pt-1">
                              <span className="text-mentor-sub-header-body mx-2">Skills : </span>
                              {mentor.mntrDtl.skills.length !== 0
                                ? (
                                  mentor.mntrDtl.skills.map((skill, prefIndx) => (
                                    <span key={skill + prefIndx.toString()} className="mentor-chip-text mx-1">{skill},
                                    </span>
                                  ))
                                ) : null }
                            </Col>
                            <Col lg="12" xs="12" className="pt-2">
                              <span className="mentor-chip-text mx-2">About Me :</span>
                              <div className="mx-2">
                                <span className="text-mentor-sub-header">{mentor.mntrDtl.abtMe}</span>
                              </div>
                            </Col>
                            <Col lg="4" xs="12" className="pt-5">
                              <Label className="pt-2">
                                <Input
                                  type="checkbox"
                                  name="isAcs"
                                  onChange={handleCheckAccess}
                                  className="campus-check-box ml-1 pt-1 cursor-pointer"
                                />
                                <span className="collapse-header">Is Access</span>
                              </Label>
                            </Col>
                            <Col lg="4" xs="12" className="pt-5">
                              <Select
                                options={loginStsOptions}
                                onChange={handleSelectLoginStsData}
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    // position: 'relative',
                                  }),
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#F6B26B' },
                                    border: '1px solid #DDE0E4',
                                    // minHeight: '25px',
                                    borderRadius: '5px',
                                    // padding: 1,
                                    marginRight: '5px',
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    color: '#575656',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                                noOptionsMessage={() => 'No Login Status Found'}
                                placeholder="Enter Login Status"
                              />
                            </Col>
                            <Col lg="4" xs="12" className="pt-5">
                              <div className="align-end">
                                <Button onClick={() => handleSubmitMentor(mentor.UserCd)} className="campus-button-oval" disabled={(mentorStatus === 'APPR' && mentorAccess !== true) || (mentorStatus !== 'APPR' && mentorStatus !== 'PADM' && mentorStatus !== 'NACC')}>
                                  <span className="mentor-text-white">Submit</span>
                                </Button>
                              </div>
                            </Col>
                            {mentorStatus === 'NACC'
                              ? (
                                <Col xs="12" className="my-4">
                                  <div className="text-right-login-campus-register pt-4 mx-1">
                                    <h6 className="input-header-text">Academy Address<span className="mandate-star">*</span></h6>
                                    <Input
                                      placeholder="Enter Rejection Reason"
                                      type="textarea"
                                      onChange={(event) => handleRejectReason(event)}
                                      className="campus-text-area"
                                    />
                                  </div>
                                </Col>
                              ) : null}
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
          <Col lg={{ size: 3, order: 2 }} md={{ size: 4, order: 2 }} xs={{ order: 1 }}>
            <div className="mx-1">
              <Card className="form-card my-3">
                <CardHeader className="form-collapse-card-header cursor-pointer">
                  <Row>
                    <Col lg="10" md="10" xs="10">
                      <FontAwesomeIcon icon={faFilter} className="collapse-icon" />
                      <span className="campus-filter-header mx-1">Filter</span>
                    </Col>
                    <Col lg="2" md="2" xs="2">
                      {activeName === 'filterBatch'
                        ? (
                          <Button onClick={() => handleCollapseClick('none')} className="button-icon-none">
                            <FontAwesomeIcon className="collapse-icon" icon={faCaretUp} />
                          </Button>
                        )
                        : (
                          <Button onClick={() => handleCollapseClick('filterBatch')} className="button-icon-none">
                            <FontAwesomeIcon className="collapse-icon" icon={faCaretDown} />
                          </Button>
                        ) }
                    </Col>
                  </Row>
                </CardHeader>
                <Collapsible
                  open={activeName === 'filterBatch'}
                  trigger={activeName ? '' : ''}
                  onOpening={() => setActiveName('filterBatch')}
                >
                  <CardBody>
                    <div>
                      Filter Data
                    </div>
                    <div className="align-center pt-2">
                      <Button className="campus-button-oval">
                        Clear All
                      </Button>
                    </div>
                  </CardBody>
                </Collapsible>
              </Card>
            </div>
          </Col>
        </Row>
      ));
};
export default ListColleges;
