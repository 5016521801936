export const setTokenToLocalStorage = (token: string) => {
  localStorage.setItem('token', token);
};

export const getTokenFromLocalStorage = () => localStorage.getItem('token') || '';

export const setTokenSessionStorage = (token: string) => {
  sessionStorage.setItem('token', token);
};

export const setUserProfileSessionStorage = (userProfile: any) => {
  sessionStorage.setItem('userProfile', userProfile);
};

export const getUserProfileSessionStorage = () => sessionStorage.getItem('userProfile');

export const setProfileUrlStorage = (urlData: string) => {
  sessionStorage.setItem('profileUrl', urlData);
};

export const getProfileUrlSessionStorage = () => sessionStorage.getItem('profileUrl') || '';

export const clearKeysFromLocalStorage = () => {
  localStorage.clear();
};

export const clearKeysFromSessionStorage = () => {
  sessionStorage.clear();
};
